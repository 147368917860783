import React, { useEffect, useState } from "react";
import "../../../src/scss/custom.css";
import { FaRegEye } from "react-icons/fa";
import Config from "../../Config/Constants";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import LoadingSpinner from "../Spinner/LoadingSpinner";

const Staffpermission = localStorage.getItem("Staffpermission");
const dealer_id = localStorage.getItem("Role_id");
const LeadReceived = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [GetLeadData, setGetLeadData] = useState([]);
  const [GetStaffAssignData, setGetStaffAssignData] = useState([]);
  const navigate = useNavigate();
  const [staffState, setStaffState] = useState({});

  // Get All Quotes for dealer
  const getStaffData = async () => {
    setIsLoading(true);
    let utoken = localStorage.getItem("Token");
    const response = await fetch(
      Config.BASEURL + `/auth/fetch_all_quote_for_dealer`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${utoken}`,
        },
        method: "POST",
        body: JSON.stringify(),
      }
    );
    const data = await response.json();
    if (data.success === true) {
      setIsLoading(false); // Hide loading screen
      setGetLeadData(data.data);
    } else {
      setIsLoading(false);
      var errorData = [];
      if (data?.data) {
        for (const [key, value] of Object.entries(data?.data)) {
          if (value.length > 0) {
            for (var i = 0; i < value.length; i++) {
              if (errorData.indexOf(value[i]) < 0) {
                errorData.push(value[i]);
              }
            }
          }
        }
      }
    }
  };
  // Get Dealer Staff Data
  const getStaffAssignData = async () => {
    setIsLoading(true);
    let utoken = localStorage.getItem("Token");
    const city_id = localStorage.getItem("city_id");
    const response = await fetch(Config.BASEURL + `/auth/fetch_dealer_staff`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${utoken}`,
      },
      method: "POST",
      body: JSON.stringify({ city_id: city_id }),
    });
    const data = await response.json();

    if (data.success === true) {
      setIsLoading(false); // Hide loading screen
      setGetStaffAssignData(data.data);
    } else if (data.error) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (dealer_id == 2) {
      navigate("/get-a-quote");
    }
    getStaffData();
    getStaffAssignData();
  }, []);

  // View details Lead

  const handleDetailsLead = (e, lead_id) => {
    e.preventDefault();
    navigate("/view-lead/" + lead_id);
  };

  // Assign Lead

  const handleAssignLead = async (e, lead_id) => {
    setIsLoading(true);
    const { name, value } = e.target.value;
    setStaffState((prevStaffState) => ({ ...prevStaffState, [name]: value }));
    let utoken = localStorage.getItem("Token");
    e.preventDefault();
    const response = await fetch(
      Config.BASEURL + `/auth/assign_lead_to_single_staff`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${utoken}`,
        },
        method: "POST",
        body: JSON.stringify({
          quote_id: lead_id,
          staff_id: e.target.value,
          dealer_id: dealer_id,
        }),
      }
    );
    const data = await response.json();
    if (data.success === true) {
      setIsLoading(false); // Hide loading screen
      toast.success(data?.message, {
        position: "top-center",
        autoClose: 1000,
      });
      window.location.reload();
    } else {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    handleAssignLead();
  }, []);

  return (
    <div className="row py-lg-5 my-3 contentArea position-relative">
      <div className="col-12">
        <div className="card h-100 shadow-sm rounded-5">
          {isLoading ? <LoadingSpinner /> : getStaffData}
          <div className="card-header bg-transparent border-0 p-4 mt-3">
            <h2>Leads Received</h2>
          </div>
          <div className="table-responsive">
            <table className="table table-md">
              <thead className="container">
                <tr>
                  <th className="text-start">Leads ID</th>
                  <th className="text-start">Sales Representative Name</th>
                  <th className="text-start">Car Make</th>
                  <th className="text-start">Car Model</th>
                  <th className="text-start">Purpose</th>
                  <th className="text-start">Date & Time</th>
                  <th className="text-center">View Leads Details</th>
                  {Staffpermission == 1 ? (
                    <th className="text-center">Assign Quote</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {GetLeadData.length > 0 ? (
                  GetLeadData.map((curElem, i) => {
                    return (
                      <tr key={`lead_${curElem.id}`}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="ms-3">
                              <td>{curElem.lead_id}</td>
                            </div>
                          </div>
                        </td>
                        <td>{curElem.lead_created_by}</td>
                        <td>{curElem.make.toUpperCase()}</td>
                        <td>{curElem.model.toUpperCase()}</td>
                        <td>{curElem.reg_number.toUpperCase()}</td>
                        <td>{curElem.created_at}</td>

                        <td className="text-center">
                          <NavLink
                            onClick={(e) =>
                              handleDetailsLead(e, curElem?.lead_id)
                            }
                            to={{
                              pathname: "/view-lead",
                            }}
                          >
                            <FaRegEye fontSize="1.3em" />
                          </NavLink>
                        </td>

                        {Staffpermission == 1 ? (
                          <td>
                            <select
                              className="form-control form-select form-select-sm"
                              onChange={(e) => {
                                handleAssignLead(e, curElem?.lead_id);
                                localStorage.setItem(
                                  "Assign",
                                  curElem?.lead_id
                                );
                              }}
                              id={"staff" + curElem?.lead_id}
                              value={
                                staffState["staff" + curElem?.lead_id]
                                  ? staffState["staff" + curElem?.lead_id]
                                  : curElem?.staff_id
                              }
                              name={"staff" + curElem?.lead_id}
                            >
                              <option value="grapefruit">Select Staff</option>
                              {isLoading ? (
                                <LoadingSpinner />
                              ) : (
                                handleAssignLead
                              )}
                              {GetStaffAssignData.map((curElem) => {
                                return (
                                  <option value={curElem.id}>
                                    {curElem.name}
                                  </option>
                                );
                              })}
                            </select>
                          </td>
                        ) : null}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="7" align="center">
                      No Record Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeadReceived;
