import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PublicRoute = ({ redirectPath = "/get-a-quote", children }) => {
  if (localStorage.getItem("Email")) {
    return <Navigate to={redirectPath} replace />;
  }

  return children ? children : <Outlet />;
};

export default PublicRoute;
