import React, { useEffect, useState } from "react";
import "../../../src/scss/custom.css";
import { FaRegEye } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Config from "../../Config/Constants";
import LoadingSpinner from "../Spinner/LoadingSpinner";

const StaffQuoteSent = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [GetLeadData, setGetLeadData] = useState([]);
  const [errorList, setErrorList] = useState([]);
  const [errorClass, setErrorClass] = useState("hide");
  const getStaffData = async () => {
    setIsLoading(true);
    let utoken = localStorage.getItem("Token");
    const response = await fetch(Config.BASEURL + `/auth/fetch_staff_lead`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${utoken}`,
      },
      method: "POST",
      body: JSON.stringify(),
    });
    const data = await response.json();

    if (data.success === true) {
      setIsLoading(false); // Hide loading screen
      setGetLeadData(data.data);
      setErrorClass("hide");
    } else {
      setIsLoading(false);
      var errorData = [];
      if (data?.data) {
        for (const [key, value] of Object.entries(data?.data)) {
          if (value.length > 0) {
            for (var i = 0; i < value.length; i++) {
              if (errorData.indexOf(value[i]) < 0) {
                errorData.push(value[i]);
              }
            }
          }
        }
        setErrorList(errorData);
        setErrorClass("show");
        setTimeout(() => {
          setErrorClass("hide");
        }, 10000);
      } else {
        setErrorList(errorData);
        setErrorClass("hide");
      }
    }
  };
  useEffect(() => {
    getStaffData();
  }, []);

  const handleDetailslead = (e, lead_id) => {
    setIsLoading(true);
    e.preventDefault();
    navigate("/staff-view-lead/" + lead_id);
  };

  const handleFilter = async (e) => {
    e.preventDefault();
    let utoken = localStorage.getItem("Token");

    const response = await fetch(Config.BASEURL + `/auth/fetch_staff_lead`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${utoken}`,
        Accept: "application/json",
      },
      body: JSON.stringify({ status: e.target.value }),
    });
    let parsedResponse = await response.json();
    if (parsedResponse?.success === true) {
      setIsLoading(false);
      setGetLeadData(parsedResponse.data);
    } else if (parsedResponse?.success === false) {
      setIsLoading(false);
      setGetLeadData(parsedResponse.data);
      toast.error(parsedResponse?.message, {
        position: "top-center",
        autoClose: 1000,
      });
    }
  };

  return (
    <>
      <div className="row py-lg-5 my-3 contentArea position-relative">
        <div className="col-12">
          <div className="card h-100 shadow-sm rounded-5">
            {isLoading ? <LoadingSpinner /> : getStaffData}
            <div className="d-flex flex-wap align-items-center justify-content-end p-3">
              <label className="mx-2">Status Filter</label>
              <select
                className="form-control form-select form-select-sm"
                onChange={(e) => {
                  handleFilter(e);
                }}
              >
                <option value="All">All</option>
                <option value="Quoted">Quoted</option>
                <option value="Pending">Yet to Quote</option>
                <option value="Accepted">Accepted</option>
                <option value="Rejected">Rejected</option>
              </select>
            </div>
            <div className="table-responsive">
              <table className="table table-md">
                <thead className="container">
                  <tr>
                    <th className="text-center">Leads ID</th>
                    <th className="text-start">Sales Representative Name</th>
                    <th className="text-start">City</th>
                    <th className="text-start">Car Make</th>
                    <th className="text-start">Car Model</th>
                    <th className="text-start">Date & Time</th>
                    <th className="text-start">Status</th>
                    <th className="text-center">View Leads</th>
                  </tr>
                </thead>

                <tbody>
                  {GetLeadData.length > 0 ? (
                    GetLeadData.map((curElem, i) => {
                      return (
                        <tr>
                          <td className="text-center">{curElem.lead_id}</td>
                          <td>{curElem.lead_created_by}</td>
                          <td>{curElem.city}</td>
                          <td>{curElem.make}</td>
                          <td>{curElem.model}</td>
                          <td>{curElem.created_at}</td>
                          <td>{curElem.status}</td>
                          <td className="text-center">
                            <NavLink
                              onClick={(e) =>
                                handleDetailslead(e, curElem?.lead_id)
                              }
                              to={{
                                pathname: "/staff-view-lead",
                              }}
                            >
                              <FaRegEye fontSize="1.3em" />
                            </NavLink>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="8" align="center">
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StaffQuoteSent;
