import React, { useEffect, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { useForm } from "react-hook-form";
import "../../../src/scss/custom.css";
import { Link, useParams } from "react-router-dom";
import Config from "../../Config/Constants";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const SendQuote = () => {
  const [SetFetchApi, setFetchLeadApi] = useState({});
  const [errorList, setErrorList] = useState([]);
  const [errorClass, setErrorClass] = useState("hide");
  const navigate = useNavigate();
  const dealer_id = localStorage.getItem("Role_id");
  const { lead_id } = useParams();
  const setFetchApi = async () => {
    let utoken = localStorage.getItem("Token");
    const response = await fetch(
      Config.BASEURL + `/auth/fetch_single_lead_details`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${utoken}`,
          Accept: "application/json",
        },
        body: JSON.stringify({ lead_id: lead_id }),
      }
    );
    const responseFetchLeadData = await response.json();
    console.log(responseFetchLeadData);
    if (responseFetchLeadData?.success) {
      setFetchLeadApi({
        ...SetFetchApi,
        ...responseFetchLeadData?.data,
      });
      // console.log("1111 - ", SetFetchApi);
    } else {
      // console.log("error");
    }
  };
  useEffect(() => {
    setFetchApi();
  }, []);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data, e) => {
    let utoken = localStorage.getItem("Token");
    e.preventDefault();
    if (data) {
      const response = await fetch(
        Config.BASEURL + `/auth/send_quote_amount_to_sales_person`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${utoken}`,
          },
          body: JSON.stringify({
            ...data,
            quote_id: lead_id,
            dealer_id: dealer_id,
          }),
        }
      );
      const responseData = await response.json();

      if (responseData?.success === true) {
        toast.success(responseData?.message, {
          position: "top-center",
          autoClose: 1000,
        });
        navigate("/lead-received", { replace: true });
        setErrorClass("hide");
      } else {
        var errorData = [];
        if (responseData?.data) {
          for (const [key, value] of Object.entries(responseData?.data)) {
            if (value.length > 0) {
              for (var i = 0; i < value.length; i++) {
                if (errorData.indexOf(value[i]) < 0) {
                  errorData.push(value[i]);
                }
              }
            }
          }
          setErrorList(errorData);
          setErrorClass("show");
          setTimeout(() => {
            setErrorClass("hide");
          }, 5000);
        } else {
          setErrorList(errorData);
          setErrorClass("hide");
        }
      }
    }
  };

  return (
    <>
      <div className="row py-lg-5 my-3 contentArea position-relative justify-content-center">
        <div className="d-flex justify-content-start mb-3">
          <Link to="/lead-received">
            <button className="btn btn-primary text-uppercase btn-md">
              <IoArrowBackOutline fontSize="1.1em" /> Back
            </button>
          </Link>
        </div>
        <div className="d-flex justify-content-center">
          <div className="col-xl-12">
            <div className="card h-100 shadow-sm rounded-5">
              <div className="card-header bg-transparent border-0 p-4 mt-3">
                <h2>Send Quote</h2>
              </div>
              <div className="px-3">
                <div
                  className={
                    "text-start alert alert-danger m-0 contentArea position-relative " +
                    errorClass
                  }
                >
                  {errorList.length > 0
                    ? errorList.map((curElem, i) => {
                        return <>{curElem}</>;
                      })
                    : null}
                </div>
              </div>
              <div className="card-body row">
                <div className="col-xl-7">
                  <div className="table-responsive">
                    <table class="table table-responsive border">
                      <thead className="container">
                        {SetFetchApi && (
                          <>
                            {/* <tr>
                            <th className="col-3 mb-3 text-start">First Name</th>
                            <td align="left" className="col-9 mb-3">
                              {SetFetchApi?.first_name ?? ""}
                            </td>
                          </tr> */}
                            <tr>
                              <th className="col-4 mb-3 text-start">
                                Vehicle Make
                              </th>
                              <td align="left" className="col-8 mb-3">
                                {SetFetchApi?.make ?? ""}
                              </td>
                            </tr>
                            <tr>
                              <th className="col-3 mb-3 text-start">
                                Vehicle Model
                              </th>
                              <td align="left" className="col-9 mb-3">
                                {SetFetchApi?.model ?? ""}
                              </td>
                            </tr>
                          </>
                        )}
                      </thead>
                    </table>
                  </div>
                </div>
                <div className="col-md-5">
                  <form id="form" onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-group mb-3">
                      <input
                        type="number"
                        className="form-control"
                        {...register("amount", {
                          required: true,
                          minLength: 2,
                          maxLength: 8,
                        })}
                        placeholder="Amount"
                      />
                      <div className="invalid-feedback mb-2">
                        {errors.amount?.type === "required" &&
                          "Amount is required"}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <textarea
                        type="text"
                        className="form-control"
                        {...register("notes", { required: true })}
                        placeholder="Comment If Any"
                      />
                      <div className="invalid-feedback mb-2">
                        {errors.notes?.type === "required" &&
                          "Text is required"}
                      </div>
                    </div>
                    <div class="d-grid">
                      <button className="btn btn-primary text-uppercase btn-md">
                        SUBMIT
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendQuote;
