import React, { useEffect, useState } from "react";
import "../../../src/scss/custom.css";
import { FaRegEdit } from "react-icons/fa";
import Config from "../../Config/Constants";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Link, NavLink } from "react-router-dom";
import "react-confirm-alert/src/react-confirm-alert.css";
import LoadingSpinner from "../Spinner/LoadingSpinner";

const Staffpermission = localStorage.getItem("Staffpermission");
const StaffMember = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [GetStaffData, setGetStaffData] = useState([]);
  const navigate = useNavigate();
  const getStaffData = async () => {
    setIsLoading(true);
    let utoken = localStorage.getItem("Token");
    const city_id = localStorage.getItem("city_id");

    const response = await fetch(Config.BASEURL + `/auth/fetch_dealer_staff`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${utoken}`,
      },
      method: "POST",
      body: JSON.stringify({ city_id: city_id }),
    });
    const data = await response.json();
    if (data.success === true) {
      setIsLoading(false); // Hide loading screen
      setGetStaffData(data.data);
    } else if (data.success === false) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (Staffpermission == 0) {
      navigate("/lead-received");
    }
    getStaffData();
  }, []);

  //Handle Edit Staff

  const handleEditStaff = (e, id) => {
    e.preventDefault();
    navigate("/update-staff/" + id);
  };

  //Handle Active STAFF
  const handleActive = async (e, id) => {
    e.preventDefault();
    let utoken = localStorage.getItem("Token");

    const response = await fetch(Config.BASEURL + `/auth/user_status_change`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${utoken}`,
        Accept: "application/json",
      },
      body: JSON.stringify({ staff_id: id, status: e.target.value }),
    });
    let parsedResponse = await response.json();
    if (parsedResponse?.success === true) {
      window.location.reload();
      toast.success(parsedResponse?.message, {
        position: "top-center",
        autoClose: 1000,
      });
    }
  };

  return (
    <div className="row py-lg-5 my-3 contentArea position-relative">
      <div className="d-flex justify-content-end mb-3">
        <Link to="/add-Staff">
          <button className="btn btn-primary text-uppercase btn-md">
            + Add Staff
          </button>
        </Link>
      </div>
      <div className="col-12">
        <div className="card h-100 shadow-sm rounded-5">
          {isLoading ? <LoadingSpinner /> : getStaffData}
          <div className="card-header bg-transparent border-0 p-4 mt-3">
            <h2>Staff Member</h2>
          </div>
          <div className="table-responsive">
            <table className="table table-md">
              <thead className="container">
                <tr>
                  <th className="text-start">Staff ID</th>
                  <th className="text-start">Staff Name</th>
                  <th className="text-start">Email</th>
                  <th className="text-start">City</th>
                  <th className="text-start">Mobile Number</th>
                  <th className="text-center">Edit</th>
                  <th className="text-center">Action</th>
                  {/* <th className="text-center">Upload File</th> */}
                </tr>
              </thead>
              <tbody>
                {GetStaffData.length > 0 ? (
                  GetStaffData.map((curElem, i) => {
                    //console.log("rrrr", curElem);
                    return (
                      <tr>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className="ms-3">
                              <td>{i + 1}</td>
                            </div>
                          </div>
                        </td>
                        <td>{curElem.name}</td>
                        <td>{curElem.email}</td>
                        <td>{curElem?.city?.city_name.toUpperCase() ?? ""}</td>
                        <td>{curElem.mobile}</td>
                        <td className="text-center">
                          <NavLink
                            onClick={(e) => handleEditStaff(e, curElem?.id)}
                            to={{
                              pathname: "/update-staff",
                            }}
                          >
                            <FaRegEdit fontSize="1.3em" />
                          </NavLink>
                        </td>
                        <td className="text-center">
                          <select
                            className="form-control form-select form-select-sm m-auto"
                            onChange={(e) => {
                              handleActive(e, curElem?.id);
                            }}
                            value={curElem.status == "0" ? 0 : 1}
                          >
                            <option value={1}>Active</option>
                            <option value={0}>Inactive</option>
                          </select>
                        </td>
                        {/* <tr> {<FileUpload />}</tr> */}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="7" align="center">
                      No Record Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaffMember;
