import React, { useEffect, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { useForm } from "react-hook-form";
import "../../../src/scss/custom.css";
import { Link, useParams } from "react-router-dom";
import Config from "../../Config/Constants";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const StaffSendQuote = () => {
  // const [uploadFile, setUploadFile] = useState();
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [errorList, setErrorList] = useState([]);
  const [errorClass, setErrorClass] = useState("hide");
  const [SetFetchApi, setFetchLeadApi] = useState({});
  const navigate = useNavigate();
  const parent_id = localStorage.getItem("parent_id");
  const staff_id = localStorage.getItem("Role_id");
  const { lead_id } = useParams();

  // Fetch Single Lead Details
  const setFetchApi = async () => {
    let utoken = localStorage.getItem("Token");
    const response = await fetch(
      Config.BASEURL + `/auth/fetch_single_lead_details`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${utoken}`,
          Accept: "application/json",
        },
        body: JSON.stringify({ lead_id: lead_id }),
      }
    );
    const responseFetchLeadData = await response.json();
    if (responseFetchLeadData?.success) {
      setFetchLeadApi({
        ...SetFetchApi,
        ...responseFetchLeadData?.data,
      });
    } else {
    }
  };

  useEffect(() => {
    setFetchApi();
  }, []);

  // End Fetch Single Lead Details

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // Send Quote Amount to Sales Person
  const onSubmit = async (data, e) => {
    let utoken = localStorage.getItem("Token");
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${utoken}`);
    var formdata = new FormData();
    formdata.append("amount", data.amount);
    formdata.append("attachment", selectedFiles);
    formdata.append("dealer_id", parent_id);
    formdata.append("notes", data.notes);
    formdata.append("quote_id", lead_id);
    formdata.append("staff_id", staff_id);

    var requestOptions = await fetch(
      Config.BASEURL + `/auth/send_quote_amount_to_sales_person`,
      {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      }
    );
    const responseData = await requestOptions.json();
    if (responseData?.success === true) {
      toast.success(responseData?.message, {
        position: "top-center",
        autoClose: 1000,
      });
      navigate("/lead-received", { replace: true });
      setErrorClass("hide");
    } else {
      var errorData = [];
      if (responseData?.data) {
        for (const [key, value] of Object.entries(responseData?.data)) {
          if (value.length > 0) {
            for (var i = 0; i < value.length; i++) {
              if (errorData.indexOf(value[i]) < 0) {
                errorData.push(value[i]);
              }
            }
          } else {
            errorData.push(responseData?.data);
          }
        }
        setErrorList(errorData);
        setErrorClass("show");
        setTimeout(() => {
          setErrorClass("hide");
        }, 5000);
      } else {
        setErrorList(errorData);
        setErrorClass("hide");
      }
    }
  };

  // End Send Quote Amount to Sales Person
  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };

  const onUploadFileChange = ({ target }) => {
    if (target.files < 1 || !target.validity.valid) {
      return;
    }
    fileToBase64(target.files[0], (err, result) => {
      if (result) {
        setSelectedFiles(result);
      }
    });
  };

  return (
    <>
      <div className="row py-lg-5 my-3 contentArea position-relative justify-content-center">
        <div className="d-flex justify-content-start mb-3">
          <Link to="/staff-quote-sent">
            <button className="btn btn-primary text-uppercase btn-md">
              <IoArrowBackOutline fontSize="1.1em" /> Back
            </button>
          </Link>
        </div>
        <div className="col-xl-8">
          <div className="card h-100 shadow-sm rounded-5">
            <div className="card-header bg-transparent border-0 p-4 mt-3">
              <h2>Send Quote</h2>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table class="table table-responsive border">
                  <thead className="container">
                    {SetFetchApi && (
                      <>
                        <tr>
                          <th className="col-3 mb-3 text-start">
                            Vehicle Make
                          </th>
                          <td align="left" className="col-9 mb-3">
                            {SetFetchApi?.make ?? ""}
                          </td>
                        </tr>
                        <tr>
                          <th className="col-3 mb-3 text-start">
                            Vehicle Model
                          </th>
                          <td align="left" className="col-9 mb-3">
                            {SetFetchApi?.model ?? ""}
                          </td>
                        </tr>
                        <tr>
                          <th className="col-3 mb-3 text-start">Quote Id</th>
                          <td align="left" className="col-md-9 mb-3">
                            {SetFetchApi?.lead_id ?? ""}
                          </td>
                        </tr>
                      </>
                    )}
                  </thead>
                </table>
              </div>
              <form id="form" onSubmit={handleSubmit(onSubmit)}>
                <div class="row">
                  <div className="col-md-6 mb-3">
                    <input
                      type="number"
                      className="form-control"
                      {...register("amount", {
                        required: true,
                        minLength: 2,
                        maxLength: 6,
                      })}
                      placeholder="Amount"
                    />
                    <div className="invalid-feedback mb-2">
                      {errors.amount?.type === "required" &&
                        "Amount is required"}
                    </div>
                  </div>
                  <div className="form-group mb-3">
                    <input
                      type="file"
                      name="filetobase64"
                      accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                      onChange={onUploadFileChange}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <textarea
                      type="text"
                      className="form-control"
                      {...register("notes", { required: true })}
                      placeholder="Comment If Any"
                    />
                    <div className="invalid-feedback mb-2">
                      {errors.notes?.type === "required" && "Text is required"}
                    </div>
                  </div>
                </div>
                <button className="btn btn-primary text-uppercase btn-md">
                  SUBMIT
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StaffSendQuote;
