import React, { Fragment, useEffect, useState } from "react";
//import GetAQuotes from "./Sales Representative/GetAQuotes";
import { IoLogOutOutline } from "react-icons/io5";
import { useLocation } from "react-router-dom";
import { IoPersonOutline } from "react-icons/io5";
import { IoNotificationsOutline } from "react-icons/io5";
import { IoMenuSharp } from "react-icons/io5";
import "react-dropdown/style.css";
import { Link, NavLink } from "react-router-dom";
import Config from "../Config/Constants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

let utoken = localStorage.getItem("Token");
const Navbar = () => {
  const handleLogout = () => {
    localStorage.clear();
  };

  const navigate = useNavigate();
  const location = useLocation();
  const [newCount, setNewCount] = useState([]);
  //destructuring pathname from location
  const { pathname } = location;
  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  const Role_id = localStorage.getItem("Role_id");

  const Name = localStorage.getItem("Name");
  const Staffpermission = localStorage.getItem("Staffpermission");
  // const notification = localStorage.getItem("count");

  const handleEditProfile = (e) => {
    e.preventDefault();
    navigate("/profile");
  };

  const handleNotification = (e) => {
    e.preventDefault();
    navigate("/notification");
    setTimeout(() => {
      window.location.reload(false);
    }, 300);

    //localStorage.setItem("count", JSON.stringify(0));
  };

  const CountNotification = async () => {
    //console.log("test");
    const response = await fetch(Config.BASEURL + `/auth/checkCount`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${utoken}`,
        Accept: "application/json",
      },
    });

    let parsedResponse = await response.json();
    if (parsedResponse?.success === true) {
      setNewCount(parsedResponse?.data);

      //localStorage.setItem("count", parsedResponse.data);
    } else if (parsedResponse?.success === false) {
      toast.error(parsedResponse?.message, {
        position: "top-center",
        autoClose: 1000,
      });
    }
  };
  useEffect(() => {
    CountNotification();
  }, []);

  return (
    <>
      <nav className="navbar navbar-expand-lg p-0 bg-white sticky-top ">
        <div className="container-fluid p-0">
          <div className="logo px-3 p-2">
            <Link to="#">
              <img src="/images/logo.svg" width="225" alt="Moo-car" />
            </Link>
          </div>
          <div
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon">
              <IoMenuSharp fontSize="1.6em" />
            </span>
          </div>
          <div
            className="collapse px-3 p-2 navbar-collapse"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              {Role_id === "2" ? (
                <Fragment>
                  <li
                    className={
                      splitLocation[1] === "get-a-quote" ? "active" : ""
                    }
                  >
                    <Link to="/get-a-quote">Get A Quote</Link>
                  </li>
                  <li
                    className={
                      splitLocation[1] === "deals-list" ? "active" : ""
                    }
                  >
                    <Link to="/deals-list">Quote List</Link>
                  </li>
                </Fragment>
              ) : Role_id === "3" ? (
                <Fragment>
                  <li
                    className={
                      splitLocation[1] === "lead-received" ? "active" : ""
                    }
                  >
                    <Link to="/lead-received">Leads Received</Link>
                  </li>

                  <li
                    className={splitLocation[1] === "lead-sent" ? "active" : ""}
                  >
                    <Link to="/lead-sent">Leads Sent</Link>
                  </li>

                  {Staffpermission == 1 ? (
                    <li
                      className={
                        splitLocation[1] === "staffmember" ? "active" : ""
                      }
                    >
                      <Link to="/staffmember">Staff Members</Link>
                    </li>
                  ) : null}
                </Fragment>
              ) : Role_id === "4" ? (
                <Fragment>
                  <li
                    className={
                      splitLocation[1] === "staff-quote-sent" ? "active" : ""
                    }
                  >
                    <Link to="/staff-quote-sent"> Leads </Link>
                  </li>
                </Fragment>
              ) : null}
            </ul>
          </div>
          <div className="userProfile">
            <ul className="navbar-nav mb-2 mb-lg-0">
              <li className="navUsername">{Name}</li>

              <li>
                <NavLink
                  onClick={(e) => handleEditProfile(e)}
                  to={{
                    pathname: "/profile",
                  }}
                >
                  <IoPersonOutline fontSize="1.6em" />
                </NavLink>
              </li>

              <li className="navNotification">
                <NavLink
                  onClick={(e) => handleNotification(e)}
                  to={{
                    pathname: "/notification",
                  }}
                >
                  <IoNotificationsOutline fontSize="1.6em" />
                  <span>{newCount}</span>
                </NavLink>
              </li>
              <li>
                <a href="/" onClick={handleLogout} className="logout">
                  <IoLogOutOutline fontSize="1.6em" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
