import React, { useEffect, useState } from "react";
import "../../../src/scss/custom.css";
import { FaRegEye } from "react-icons/fa";
import Config from "../../Config/Constants";
import { NavLink, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { useNavigate } from "react-router";

const QuoteSent = () => {
  const { lead_id } = useParams();
  const navigate = useNavigate();
  const [GetQuoteData, setGetQuoteData] = useState([]);
  const [errorList, setErrorList] = useState([]);
  const [errorClass, setErrorClass] = useState("hide");
  const [isLoading, setIsLoading] = useState(false);
  const getQuoteData = async (e) => {
    setIsLoading(true);
    let utoken = localStorage.getItem("Token");
    const response = await fetch(Config.BASEURL + `/auth/sent_all_quote_list`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${utoken}`,
      },
      method: "POST",
      body: JSON.stringify({ status: "All" }),
    });
    const data = await response.json();
    if (data.success === true) {
      setIsLoading(false); // Hide loading screen
      setGetQuoteData(data.data);
      setErrorClass("hide");
    } else {
      setIsLoading(false);
      var errorData = [];
      if (data?.message) {
        for (const [key, value] of Object.entries(data?.message)) {
          if (value.length > 0) {
            for (var i = 0; i < value.length; i++) {
              if (errorData.indexOf(value[i]) < 0) {
                errorData.push(value[i]);
              }
            }
          }
        }
        setErrorList(errorData);
        setErrorClass("show");
        setTimeout(() => {
          setErrorClass("hide");
        }, 5000);
      } else {
        setErrorList(errorData);
        setErrorClass("hide");
      }
    }
  };

  useEffect(() => {
    getQuoteData();
  }, []);

  const handleDetailslead = (e, lead_id) => {
    e.preventDefault();
    navigate("/view-quote-lead/" + lead_id);
  };
  const handleFilter = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    let utoken = localStorage.getItem("Token");

    const response = await fetch(Config.BASEURL + `/auth/sent_all_quote_list`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${utoken}`,
        Accept: "application/json",
      },
      body: JSON.stringify({ status: e.target.value }),
    });
    let parsedResponse = await response.json();
    console.log("parsedResponse", parsedResponse);
    if (parsedResponse?.success === true) {
      setIsLoading(false);
      setGetQuoteData(parsedResponse.data);
    } else if (parsedResponse?.success === false) {
      setIsLoading(false);
      setGetQuoteData(parsedResponse.data);
      toast.error(parsedResponse?.message, {
        position: "top-center",
        autoClose: 1000,
      });
    }
  };

  return (
    <>
      <div className="row py-lg-5 my-3 contentArea position-relative">
        <div className="col-12">
          <div className="card h-100 shadow-sm rounded-5">
            {isLoading ? <LoadingSpinner /> : getQuoteData}
            <div className="card-header bg-transparent border-0 p-4 mt-3">
              <h2>Leads Sent</h2>
            </div>
            <div className="d-flex flex-wap align-items-center justify-content-end p-3">
              <label className="mx-2">Status Filter</label>
              <select
                className="form-control form-select form-select-sm"
                onChange={(e) => {
                  handleFilter(e);
                }}
              >
                <option value="All">All</option>
                <option value="Quoted">Quoted</option>
                <option value="Accepted">Accepted</option>
                <option value="Rejected">Rejected</option>
              </select>
            </div>
            <div className="table-responsive">
              <table className="table table-md">
                <thead className="container">
                  <tr>
                    <th className="text-start">Leads ID</th>
                    <th className="text-start">Sales Representative Name</th>
                    <th className="text-start">Assign</th>
                    <th className="text-start">Car Model</th>
                    <th className="text-start">Purpose</th>
                    <th className="text-start">Amount</th>
                    <th className="text-start">Date & Time</th>
                    <th className="text-center">View Leads Details</th>
                    <th className="text-start">Status</th>
                  </tr>
                </thead>

                <tbody>
                  {/* <div
                  className={
                    "text-start alert alert-danger contentArea position-relative " +
                    errorClass
                  }
                >
                  {errorList.length > 0
                    ? errorList.map((curElem, i) => {
                        return <>{curElem}</>; 
                      })
                    : null}
                </div> */}
                  {GetQuoteData.length > 0 ? (
                    GetQuoteData.map((curElem, i) => {
                      return (
                        <tr>
                          <td>
                            <div className="d-flex align-items-center">
                              <div className="ms-3">
                                <td>{curElem.lead_id}</td>
                              </div>
                            </div>
                          </td>
                          <td>{curElem.lead_created_by}</td>
                          <td>{curElem.lead_assigned_to}</td>
                          <td>{curElem.model.toUpperCase()}</td>
                          <td>{curElem.reg_number.toUpperCase()}</td>
                          <td>{curElem.amount}</td>
                          <td>{curElem.created_at}</td>

                          <td className="text-center">
                            <NavLink
                              onClick={(e) =>
                                handleDetailslead(e, curElem?.lead_id)
                              }
                              to={{
                                pathname: "/view-quote-lead",
                              }}
                            >
                              <FaRegEye fontSize="1.3em" />
                            </NavLink>
                          </td>

                          <td>{curElem.status}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="8" align="center">
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuoteSent;
