import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
//import { useNavigate } from "react-router";
import BodyClassName from "react-body-classname";
import { toast } from "react-toastify";
import Config from "../../src/Config/Constants";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router";

const ResetPassword = () => {
  let location = useLocation();
  const navigate = useNavigate();
  const [errorList, setErrorList] = useState([]);
  const [errorClass, setErrorClass] = useState("hide");
  const [userToken, setUserToken] = useState(null);

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm();
  const { isSubmitting } = formState;
  //console.log("location.pathname", location.pathname);

  useEffect(() => {
    let token =
      location.pathname && location.pathname.split("/").slice(-1)?.[0];
    setUserToken(token);
  }, []);

  const onSubmit = async (data, e, password) => {
    //let email = localStorage.getItem("email");
    e.preventDefault();
    if (data) {
      const response = await fetch(Config.BASEURL + `/auth/password/reset`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...data, token: userToken }),
      });
      const responseData = await response.json();

      // console.log("responseData", responseData);
      if (responseData?.success === true) {
        toast.success(responseData?.message, {
          position: "top-center",
          autoClose: 5000,
        });
        navigate("/", { replace: true });
      } else {
        var errorData = [];
        if (responseData?.data.errors) {
          //console.log("responseData?.message", responseData?.data.errors.password);
          for (const [key, value] of Object.entries(
            responseData?.data.errors
          )) {
            if (value.length > 0) {
              for (var i = 0; i < value.length; i++) {
                if (errorData.indexOf(value[i]) < 0) {
                  errorData.push(value[i]);
                }
              }
            }
          }
          setErrorList(errorData);
          setErrorClass("show");
          setTimeout(() => {
            setErrorClass("hide");
          }, 5000);
        } else {
          setErrorList(errorData);
          setErrorClass("hide");
        }
      }
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 5000);
    });
  };
  return (
    <>
      <BodyClassName className="darkBg"></BodyClassName>
      <div className="d-flex align-items-center justify-content-center h-100">
        <div className="w-100">
          <div className="card h-100 shadow-sm rounded-5 overflow-hidden">
            <div className="row align-items-center">
              <div className="col-md-5">
                <div className="sidebarLeft">
                  <div className="sidebarLeftInner position-relative d-flex align-items-center justify-content-center flex-column">
                    <div className="logo-login">
                      <img
                        src="/images/logo-black.svg"
                        width="225"
                        alt="Moo-car"
                      />
                    </div>
                    <div className="carImage pt-2 pb-4">
                      <img src="/images/car.png" alt="Moo-car" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="frontForm">
                  <div
                    className={
                      "text-start alert alert-danger contentArea position-relative " +
                      errorClass
                    }
                  >
                    {errorList.length > 0
                      ? errorList.map((curElem, i) => {
                          return <>{curElem}</>;
                        })
                      : null}
                  </div>
                  <h2 className="m-0">Reset Password?</h2>
                  {/* <p className="primary-colour">Please login to continue</p> */}
                  <form
                    id="form"
                    className="flex flex-col"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="form-group mb-3">
                      <input
                        type="password"
                        className="form-control"
                        name="password"
                        {...register("password", { required: true })}
                        placeholder="New Password"
                        // value={password}
                        // onChange={(e) => setPassword(e.target.value)}
                      />
                      <div className="invalid-feedback mb-2">
                        {errors.password?.type === "required" &&
                          "New Password is required"}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <input
                        type="password"
                        name="password_confirmation"
                        className="form-control"
                        {...register("password_confirmation", {
                          required: true,
                        })}
                        placeholder="Verify Password"
                      />
                      <div className="invalid-feedback mb-2">
                        {errors.password?.type === "required" &&
                          "To confirm, type the new password again."}
                      </div>
                    </div>
                    <div className="d-grid">
                      <button
                        disabled={isSubmitting}
                        className="btn btn-primary text-uppercase btn-lg"
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-1"></span>
                        )}
                        Save
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
