import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import Config from "../../Config/Constants";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { IoArrowBackOutline } from "react-icons/io5";
import { NavLink } from "react-router-dom";

const ChooseDealers = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const utoken = localStorage.getItem("Token");
  // const [errorList, setErrorList] = useState([]);
  const [errorClass, setErrorClass] = useState("hide");

  const [GetDealersData, setGetDealersData] = useState([]);
  const [GetData, setDealersData] = useState([]);
  const [selectedDealers, setSelectedDealers] = useState({});
  const [dealerName, setDealerName] = useState("");

  const getDealerData = async () => {
    let utoken = localStorage.getItem("Token");
    const city_id = localStorage.getItem("city_id");
    const cus_id = localStorage.getItem("cus_id");
    const state = localStorage.getItem("state");
    const LatestID = localStorage.getItem("LatestID");
    // console.log('choose deal',cus_id,'LatestID--',LatestID);
    const response = await fetch(
      Config.BASEURL + `/auth/get_quote_form_dealer_list_by_city_id`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${utoken}`,
        },
        method: "POST",
        body: JSON.stringify({
          city_id: city_id,
          cus_id: cus_id,
          state: state,
        }),
      }
    );
    const data = await response.json();
    //console.log("data....", data.message);
    if (data.success === true) {
      setGetDealersData(data.data);
      setErrorClass("hide");
    } else if (data.success === false) {
      setDealersData(data.error);
    }
  };

  useEffect(() => {
    getDealerData();
  }, []);

  const SubmitDetails = async (e) => {
    const getformid = localStorage.getItem("cus_id");
    const LatestID = localStorage.getItem("LatestID");
    setIsLoading(true);
    e.preventDefault();
    var data = {
      quote_id: getformid,
      dealer_name: dealerName,
      name: dealerName,
    };
    let selectedData = Object.entries(selectedDealers)
      .filter(([key, val]) => val)
      .map(([key, val]) => {
        return parseInt(key);
      });

    data.dealers_id = selectedData;
    //console.log("sssssss", data);
    const response = await fetch(
      Config.BASEURL + `/auth/send_quote_to_dealers`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${utoken}`,
          Accept: "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    const responseData = await response.json();
    //console.log("responseData", responseData);

    if (responseData?.success === true) {
      setIsLoading(false); // Hide loading screen
      localStorage.removeItem("LatestID");
      navigate("/submitquote", { replace: true });
    } else if (responseData?.success === false) {
      setIsLoading(false);
      toast.error("Please choose car dealer", {
        position: "top-center",
        autoClose: 5000,
      });
    }
  };

  const handleChange = (e, id, name) => {
    setDealerName(name);
    let isChecked = e.target.checked;
    setSelectedDealers({
      ...selectedDealers,
      [id]: isChecked,
    });
  };
  const handleBackFetchApi = (e) => {
    e.preventDefault();
    navigate("/customerdetails/");
  };

  return (
    <>
      <div className="row py-lg-5 my-3 contentArea position-relative">
        <div className="col-lg-2">
          <Sidebar />
        </div>
        <div className="col-lg-10">
          <div className="card h-100 shadow-sm rounded-5">
            {isLoading ? <LoadingSpinner /> : getDealerData}
            <div className="card-header bg-transparent border-0 p-4 mt-3">
              <h2>Choose The Dealers To Send The Quote</h2>
            </div>
            <div className="card-body p-0">
              <div className="table-responsive">
                <table className="table table-md">
                  <thead className="container">
                    <tr>
                      <th className="text-start">S.No.</th>
                      <th className="text-start">Car Dealer Name</th>
                      <th className="text-start">City</th>
                      <th className="text-start">State</th>
                      <th className="text-start">Postcode</th>
                      <th className="text-start">Car Make</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {GetDealersData.length > 0 ? (
                      GetDealersData.map((curElem, i) => {
                        return (
                          <tr key={`dealer_${curElem?.id}`}>
                            <td>
                              <div className="d-flex align-items-center">
                                <div className="ms-3">
                                  <td>{i + 1}</td>
                                </div>
                              </div>
                            </td>
                            <td>{curElem.name}</td>
                            <td>{curElem.city.toUpperCase()}</td>
                            <td>{curElem.state.toUpperCase()}</td>
                            <td>{curElem.postcode.toUpperCase()}</td>
                            <td>{curElem.make.toUpperCase()}</td>
                            <td className="text-center">
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value="isChecked"
                                  id="flexCheckDefault"
                                  onChange={(e) =>
                                    handleChange(e, curElem?.id, curElem.name)
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="5" align="center">
                          There are no car dealers for the state and car make
                          you selected. Please try other State and Car Make!
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className="d-flex justify-content-between mb-3 px-3 flex-wrap">
                <NavLink
                  onClick={(e) => handleBackFetchApi(e)}
                  to={{
                    pathname: "/customerdetails",
                  }}
                >
                  <button className="btn btn-primary text-uppercase btn-md">
                    <IoArrowBackOutline fontSize="1.1em" /> Back
                  </button>
                </NavLink>
                {GetData == "" ? (
                  <input
                    disabled={isLoading}
                    className="btn btn-primary text-uppercase btn-md"
                    value="Submit"
                    type="Submit"
                    onClick={SubmitDetails}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChooseDealers;
