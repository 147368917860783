import React, { useEffect, useState, useRef } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import "../../../src/scss/custom.css";
import { Link, useParams } from "react-router-dom";
import Config from "../../Config/Constants";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { BiPrinter } from "react-icons/bi";
import { useReactToPrint } from "react-to-print";
import Test from "../PrintDealerViewLead";

const Role_id = localStorage.getItem("Role_id");
const StaffViewLead = () => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [SetFetchApi, setFetchLeadApi] = useState({});
  const { lead_id } = useParams();
  const navigate = useNavigate();
  const [newStatus, setNewStatus] = useState([]);

  const setFetchApi = async () => {
    let utoken = localStorage.getItem("Token");
    const response = await fetch(
      Config.BASEURL + `/auth/fetch_single_lead_details`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${utoken}`,
          Accept: "application/json",
        },
        body: JSON.stringify({ lead_id: lead_id }),
      }
    );
    const responseFetchLeadData = await response.json();
    console.log(responseFetchLeadData);
    if (responseFetchLeadData?.success) {
      setFetchLeadApi({
        ...SetFetchApi,
        ...responseFetchLeadData?.data,
      });
      setNewStatus(responseFetchLeadData?.data.new_status);
      localStorage.setItem("staff_id", responseFetchLeadData.data.staff_id);
    } else {
      // console.log("error");
    }
  };
  useEffect(() => {
    setFetchApi();
  }, []);

  const {
    register,
    formState,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { isSubmitting } = formState;

  const [errorList, setErrorList] = useState([]);
  const [errorClass, setErrorClass] = useState("hide");
  const onSubmit = async (data, e) => {
    const dealer_id = localStorage.getItem("Role_id");
    const staff_id = localStorage.getItem("staff_id");
    let utoken = localStorage.getItem("Token");

    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${utoken}`);
    var formdata = new FormData();
    formdata.append("amount", data.amount);
    formdata.append("attachment", selectedFiles);
    formdata.append("dealer_id", dealer_id);
    formdata.append("notes", data?.notes || "");
    formdata.append("quote_id", lead_id);
    formdata.append("staff_id", staff_id);
    var requestOptions = await fetch(
      Config.BASEURL + `/auth/send_quote_amount_to_sales_person`,
      {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      }
    );
    const responseData = await requestOptions.json();
    if (responseData?.success === true) {
      toast.success(responseData?.message, {
        position: "top-center",
        autoClose: 1000,
      });
      navigate("/lead-received", { replace: true });
      setErrorClass("hide");
    } else {
      var errorData = [];
      if (responseData?.data) {
        for (const [key, value] of Object.entries(responseData?.data)) {
          if (value.length > 0) {
            for (var i = 0; i < value.length; i++) {
              if (errorData.indexOf(value[i]) < 0) {
                errorData.push(value[i]);
              }
            }
          } else {
            errorData.push(responseData?.data);
          }
        }
        setErrorList(errorData);
        setErrorClass("show");
        setTimeout(() => {
          setErrorClass("hide");
        }, 5000);
      } else {
        setErrorList(errorData);
        setErrorClass("hide");
      }
    }
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };

  const onUploadFileChange = ({ target }) => {
    if (target.files < 1 || !target.validity.valid) {
      return;
    }
    fileToBase64(target.files[0], (err, result) => {
      if (result) {
        setSelectedFiles(result);
      }
    });
  };

  return (
    <>
      {Role_id === "4" ? (
        <div className="row py-lg-5 my-3 contentArea position-relative justify-content-center">
          <div className="d-flex justify-content-between mb-3">
            <Link to="/staff-quote-sent">
              <button className="btn btn-primary text-uppercase btn-md">
                <IoArrowBackOutline fontSize="1.1em" /> Back
              </button>
            </Link>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary text-uppercase btn-md"
                onClick={handlePrint}
              >
                <BiPrinter fontSize="1.5em" /> Print
              </button>
            </div>
          </div>
          <div className="d-flex justify-content-center row">
            <Test ref={componentRef} />
            {newStatus == 1 ? (
              <div className="col-xl-4 mb-3">
                <div className="card h-100 shadow-sm rounded-5">
                  <div className="card-header bg-transparent border-0 p-4 mt-3">
                    <h2>Send Quote</h2>
                  </div>
                  <div className="card-body quoteTable row">
                    <div
                      className={
                        "contentArea text-start alert alert-danger position-relative  " +
                        errorClass
                      }
                    >
                      {errorList.length > 0
                        ? errorList.map((curElem, i) => {
                            return <>{curElem}</>;
                          })
                        : null}
                    </div>
                    <form id="form" onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          {...register("amount", {
                            required: true,
                            minLength: 2,
                            maxLength: 6,
                          })}
                          placeholder="Amount"
                        />
                      </div>
                      <div className="form-group mb-3">
                        <div className="text-start form-control">
                          <input
                            type="file"
                            name="filetobase64"
                            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            onChange={onUploadFileChange}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <textarea
                          type="textarea"
                          className="form-control quote-textarea"
                          {...register("notes")}
                          placeholder="Comment If Any"
                        />
                      </div>
                      <div class="d-grid">
                        <button
                          disabled={isSubmitting}
                          className="btn btn-primary text-uppercase btn-md"
                        >
                          {isSubmitting && (
                            <span className="spinner-border spinner-border-sm mr-1"></span>
                          )}
                          SUBMIT
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : (
        navigate("/staff-quote-sent", { replace: true })
      )}
    </>
  );
};

export default StaffViewLead;