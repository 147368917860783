import "./App.css";
import Routes from "./Router/Routes";
import Navbar from "./Components/Navbar";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

function App() {
  var hours = 3; // to clear the localStorage after 1 hour
  // (if someone want to clear after 8hrs simply change hours=8)
  var now = new Date().getTime();
  var setupTime = localStorage.getItem("setupTime");
  if (setupTime == null) {
    localStorage.setItem("setupTime", now);
  } else {
    if (now - setupTime > hours * 60 * 60 * 1000) {
      localStorage.clear();
      window.location.reload();
      localStorage.setItem("setupTime", now);
    }
  }
  // const email = localStorage.getItem("Email");
  // console.log(email);

  return (
    <>
      {/* {email && <Navbar />} */}

      <Navbar />
      <div className="App">
        <div className="container-xl">
          <Routes />
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default App;
