import React from "react";
import "../App.css";
import { IoCarOutline } from "react-icons/io5";
import { IoCheckboxOutline } from "react-icons/io5";
import { IoPersonOutline } from "react-icons/io5";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  const handleClick = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <nav id="sidebar" className="sidebarBlock mb-3">
        <ul className="list-unstyled components sidebarBtn row">
          <li className="col-lg-12 col-4">
            <NavLink
              onClick={(e) => handleClick(e)}
              activeclassname="is-active"
              className="text-uppercase lh-sm shadow-sm rounded-5 align-items-center justify-content-center d-flex flex-column"
              to="/get-a-quote"
            >
              <div className="icons mb-3">
                <IoCarOutline fontSize="2.5em" />
              </div>
              <div className="menuText">
                Vehicle <br />
                Details
              </div>
            </NavLink>
          </li>
          <li className="col-lg-12 col-4">
            <NavLink
              onClick={(e) => handleClick(e)}
              activeclassname="is-active"
              className="text-uppercase lh-sm shadow-sm rounded-5 align-items-center justify-content-center d-flex flex-column"
              to="/customerdetails"
            >
              <div className="icons mb-3">
                <IoPersonOutline fontSize="2.5em" />
              </div>
              <div className="menuText">
                Customer <br /> Details
              </div>
            </NavLink>
          </li>
          <li className="col-lg-12 col-4">
            <NavLink
              onClick={(e) => handleClick(e)}
              activeclassname="is-active"
              className="text-uppercase lh-sm shadow-sm rounded-5 align-items-center justify-content-center d-flex flex-column"
              to="/choosedealers"
            >
              <div className="icons mb-3">
                <IoCheckboxOutline fontSize="2.5em" />
              </div>
              <div className="menuText">
                Choose <br /> Dealers
              </div>
            </NavLink>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default Sidebar;
