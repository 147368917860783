import React, { useEffect, useState } from "react";
import { VscCloudDownload } from "react-icons/vsc";

import "../../src/scss/custom.css";
import { useParams } from "react-router-dom";
import Config from "../Config/Constants";
import { toast } from "react-toastify";

const Test = React.forwardRef((props, ref) => {
  const [SetFetchApi, setFetchLeadApi] = useState({});
  const { lead_id } = useParams();
  const setFetchApi = async () => {
    let utoken = localStorage.getItem("Token");
    const response = await fetch(
      Config.BASEURL + `/auth/fetch_single_lead_details`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${utoken}`,
          Accept: "application/json",
        },
        body: JSON.stringify({ lead_id: lead_id }),
      }
    );
    const responseFetchLeadData = await response.json();
    //console.log(responseFetchLeadData);
    if (responseFetchLeadData?.success === true) {
      setFetchLeadApi({
        ...SetFetchApi,
        ...responseFetchLeadData?.data,
      });
    } else if (responseFetchLeadData?.success === false) {
      toast.error(responseFetchLeadData?.message, {
        position: "top-center",
        autoClose: 1000,
      });
    }
  };
  useEffect(() => {
    setFetchApi();
  }, []);

  return (
    <>
      <div className="col-xl-8 mb-3" ref={ref}>
        <div className="card h-100 shadow-sm rounded-5">
          <div className="card-header bg-transparent border-0 p-4 mt-3">
            <h2>Quote Details</h2>
          </div>
          <div className="card-body quoteTable row">
            <div class="table-responsive">
              <table class="table table-responsive border">
                <thead className="container">
                  {SetFetchApi && (
                    <>
                      <tr>
                        <th align="left" className="col-3 mb-3 text-start">
                          Lead Creator
                        </th>
                        <td align="left" className="col-md-6 mb-3">
                          {SetFetchApi?.lead_created_by ?? ""}
                        </td>
                      </tr>

                      <tr>
                        <th align="left" className="col-3 mb-3 text-start">
                          Vehicle Make
                        </th>
                        <td align="left" className="col-md-6 mb-3 upper">
                          {SetFetchApi?.make ?? ""}
                        </td>
                      </tr>
                      <tr>
                        <th align="left" className="col-3 mb-3 text-start">
                          Vehicle Model
                        </th>
                        <td align="left" className="col-md-6 mb-3 upper">
                          {SetFetchApi?.model ?? ""}
                        </td>
                      </tr>

                      <tr>
                        <th align="left" className="col-3 mb-3 text-start">
                          Variant
                        </th>
                        <td align="left" className="col-md-6 mb-3 upper">
                          {SetFetchApi?.vehicle_variant_id ?? ""}
                        </td>
                      </tr>
                      <tr>
                        <th align="left" className="col-3 mb-3 text-start">
                          Vehicle Body Type
                        </th>
                        <td align="left" className="col-md-6 mb-3 upper">
                          {SetFetchApi?.body_type ?? ""}
                        </td>
                      </tr>
                      <tr>
                        <th align="left" className="col-3 mb-3 text-start">
                          Transmission Type
                        </th>
                        <td align="left" className="col-md-6 mb-3 upper">
                          {SetFetchApi?.vehicle_transmission_type_id ?? ""}
                        </td>
                      </tr>
                      <tr>
                        <th align="left" className="col-3 mb-3 text-start">
                          Optional Options
                        </th>
                        <td align="left" className="col-md-6 mb-3 upper">
                          {SetFetchApi?.vehicle_optional_id ?? ""}
                        </td>
                      </tr>
                      <tr>
                        <th align="left" className="col-3 mb-3 text-start">
                          Vehicle Color
                        </th>
                        <td align="left" className="col-md-6 mb-3">
                          {SetFetchApi?.vehicle_color ?? ""}
                        </td>
                      </tr>
                      <tr>
                        <th align="left" className="col-3 mb-3 text-start">
                          Vehicle Urgency
                        </th>
                        <td align="left" className="col-md-6 mb-3 upper">
                          {SetFetchApi?.urgency ?? ""}
                        </td>
                      </tr>
                      <tr>
                        <th align="left" className="col-3 mb-3 text-start">
                          Purpose
                        </th>
                        <td align="left" className="col-md-6 mb-3 upper">
                          {SetFetchApi?.reg_number ?? ""}
                        </td>
                      </tr>
                      <tr>
                        <th align="left" className="col-3 mb-3 text-start">
                          Sales Rep. Notes
                        </th>
                        <td align="left" className="col-md-6 mb-3">
                          <pre>{SetFetchApi?.sales_notes ?? ""}</pre>
                        </td>
                      </tr>
                      <tr>
                        <th align="left" className="col-3 mb-3 text-start">
                          Amount
                        </th>
                        <td align="left" className="col-md-6 mb-3">
                          {SetFetchApi?.amount ?? ""}
                        </td>
                      </tr>
                      <tr>
                        <th align="left" className="col-3 mb-3 text-start">
                          Dealer Notes
                        </th>
                        <td align="left" className="col-md-6 mb-3">
                          <pre>{SetFetchApi?.notes ?? ""}</pre>
                        </td>
                      </tr>
                      <tr>
                        <th align="left" className="col-3 mb-3 text-start">
                          Status
                        </th>
                        <td align="left" className="col-md-6 mb-3">
                          {SetFetchApi?.status ?? ""}
                        </td>
                      </tr>
                      <tr className="attach">
                        <th align="left" className="col-3 mb-3 text-start">
                          Attachment
                        </th>
                        <td align="left" className="col-md-6 mb-3">
                          {SetFetchApi?.attachment !== "" ? (
                            <a
                              target="_blank"
                              rel="noreferrer"
                              className="pdfIcon"
                              href={SetFetchApi?.attachment}
                            >
                              <VscCloudDownload fontSize="1.8em" />
                            </a>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    </>
                  )}
                </thead>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default Test;