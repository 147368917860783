import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Sidebar from "../Sidebar";
import { IoArrowForwardOutline } from "react-icons/io5";
import Config from "../../Config/Constants";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { IoArrowBackOutline } from "react-icons/io5";
import { NavLink } from "react-router-dom";

export default function Customerdetails() {
  const navigate = useNavigate();
  let utoken = localStorage.getItem("Token");
  let City_id = localStorage.getItem("city_id");
  const getformid = localStorage.getItem("LatestID");
  const [formFieldsData, setFormFieldsData] = useState({ city: [], state: [] });
  const [errorList, setErrorList] = useState([]);
  const [errorClass, setErrorClass] = useState("hide");
  const [selectedOption, setSelectedOption] = useState({});

  const getSelectOptions = async () => {
    const id = localStorage.getItem("LatestID");
    const response = await fetch(Config.BASEURL + `/auth/get_quote_form_meta`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${utoken}`,
        Accept: "application/json",
      },
    });
    let parsedResponse = await response.json();
    if (parsedResponse?.success === true) {
      setFormFieldsData({
        ...formFieldsData,
        ...parsedResponse?.data,
      });
      if (id) {
        setCustomerDetailsFetchApi(parsedResponse?.data);
      }
    } else if (parsedResponse?.success === false) {
      toast.error(parsedResponse?.message, {
        position: "top-center",
        autoClose: 1000,
      });
    }
  };

  const setCustomerDetailsFetchApi = async (fieldsData) => {
    const LatestID = localStorage.getItem("LatestID");
    const cus_id = localStorage.getItem("cus_id");

    const response = await fetch(
      Config.BASEURL + `/auth/fetch_recent_created_lead`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${utoken}`,
          Accept: "application/json",
        },
        body: JSON.stringify({
          id: LatestID ? LatestID : cus_id,
        }),
      }
    );
    const responseStaffData = await response.json();
    if (responseStaffData?.success) {
      let data = responseStaffData?.data;

      if (fieldsData?.city && fieldsData?.city?.length > 0 && data?.city_id) {
        let selectedCity = fieldsData?.city.filter(
          (item) => data?.city_id == item.id
        )?.[0];
        setSelectedOption(selectedCity);
      }

      reset({
        address2: data?.address2 || "",
        address: data?.address || "",
        city_id: data?.city_id || "",
        email: data?.email || "",
        first_name: data?.first_name || "",
        last_name: data?.last_name || "",
        zip_code: data?.zip_code || "",
        state: data?.state || "",
        emp_number: data?.emp_number || "",
        mobile: data?.mobile || "",
        id: data?.id || "",
      });
    }
  };

  const handleBackFetchApi = (e) => {
    e.preventDefault();
    navigate("/get-a-quote/");
    window.location.reload();
  };

  useEffect(() => {
    getSelectOptions();
  }, []);

  const {
    register,
    handleSubmit,
    formState,
    reset,
    formState: { errors },
  } = useForm();
  const { isSubmitting } = formState;

  // Customer details Form submit

  const onSubmit = async (data, e) => {
    const LatestID = localStorage.getItem("LatestID"); // on next button click after confirm back id is passing
    let formdata = {
      ...data,
      city_id: selectedOption.id,
    };
    let utoken = localStorage.getItem("Token");
    e.preventDefault();
    if (data) {
      const response = await fetch(
        Config.BASEURL + `/auth/get_quote_form_meta_add_cutomer_details`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${utoken}`,
          },
          body: JSON.stringify(formdata, { id: LatestID }),
        }
      );
      const responseData = await response.json();

      if (responseData?.success === true) {
        localStorage.setItem("city_id", formdata.city_id);
        localStorage.setItem("state", formdata.state);
        localStorage.setItem("cus_id", formdata.id);
        toast.success(responseData?.message, {
          position: "top-center",
          autoClose: 1000,
        });
        navigate("/choosedealers", { replace: true });
        setErrorClass("hide");
      } else {
        var errorData = [];
        if (responseData?.data) {
          for (const [key, value] of Object.entries(responseData?.data)) {
            if (value.length > 0) {
              for (var i = 0; i < value.length; i++) {
                if (errorData.indexOf(value[i]) < 0) {
                  errorData.push(value[i]);
                }
              }
            }
          }
          setErrorList(errorData);
          setErrorClass("show");
          setTimeout(() => {
            setErrorClass("hide");
          }, 5000);
        } else {
          setErrorList(errorData);
          setErrorClass("hide");
        }
      }
    }
  };
  const onChangeSelectionCity = (val) => {
    let selectedval = formFieldsData.city.filter((item) => item.id == val)?.[0];
    setSelectedOption(selectedval);
  };

  return (
    <>
      <div className="row py-lg-5 my-3 contentArea position-relative">
        <div className="col-lg-2">
          <Sidebar />
        </div>
        <div className="col-lg-10">
          <div className="card h-100 shadow-sm rounded-5">
            <div className="card-header bg-transparent border-0 p-4 mt-3">
              <h2>Customer Details</h2>
            </div>
            <div className="card-body pt-lg-2 p-lg-5">
              <div
                className={
                  "contentArea text-start alert alert-danger position-relative  " +
                  errorClass
                }
              >
                {errorList.length > 0
                  ? errorList.map((curElem, i) => {
                      return <>{curElem}</>;
                    })
                  : null}
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <input
                  type="hidden"
                  className="form-control"
                  {...register("id")}
                  placeholder="Id"
                  value={getformid}
                />
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <div className="form-group text-start">
                      <label className="mb-1">First Name</label>
                      <input
                        className="form-control"
                        {...register("first_name", { required: true })}
                        placeholder="First Name"
                      />

                      <div className="invalid-feedback">
                        {errors?.first_name?.type === "required" &&
                          "First Name is required"}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group text-start">
                      <label className="mb-1">Last Name</label>
                      <input
                        className="form-control"
                        {...register("last_name", { required: true })}
                        placeholder="Last Name"
                      />
                      <div className="invalid-feedback">
                        {errors?.last_name?.type === "required" &&
                          "Last Name is required"}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-3">
                    <div className="form-group text-start">
                      <label className="mb-1">Email</label>
                      <input
                        className="form-control"
                        {...register("email", { required: true })}
                        placeholder="Email"
                      />
                      <div className="invalid-feedback">
                        {errors?.email?.type === "required" &&
                          "Email is required"}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group text-start">
                      <label className="mb-1">Mobile number</label>
                      <input
                        className="form-control"
                        {...register("mobile")}
                        placeholder="Mobile number"
                      />
                      <div className="invalid-feedback">
                        {errors?.mobile?.type === "required" &&
                          "Mobile number is required"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <div className="form-group text-start">
                      <label className="mb-1">Employer/ABN holder</label>
                      <input
                        className="form-control"
                        {...register("emp_number", { required: true })}
                        placeholder="Employer/ABN holder"
                      />
                      <div className="invalid-feedback">
                        {errors?.emp_number?.type === "required" &&
                          "Employer/ABN holder is required"}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group text-start">
                      <label className="mb-1">Address Line 1</label>
                      <input
                        className="form-control"
                        {...register("address", {
                          required: true,
                        })}
                        placeholder="Address Line 1 "
                      />
                      <div className="invalid-feedback">
                        {errors?.address?.type === "required" &&
                          "Address Line 1 is required"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <div className="form-group text-start">
                      <label className="mb-1">Address Line 2</label>
                      <input
                        className="form-control"
                        {...register("address2", {
                          required: false,
                        })}
                        placeholder="Address Line 2 "
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group text-start">
                      <label className="mb-1">Postcode</label>
                      <input
                        className="form-control"
                        {...register("zip_code", {
                          required: false,
                        })}
                        placeholder="Postcode"
                      />
                      <div className="invalid-feedback">
                        {errors?.zip_code?.type === "required" &&
                          "Post Code is required"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="mb-3 col-md-6">
                    <div className="form-group text-start">
                      <label className="mb-1">City/Suburb</label>
                      <select
                        name="city_id"
                        id="city_id"
                        value={selectedOption.id}
                        className="form-control form-select city_id"
                        onChange={(e) => onChangeSelectionCity(e.target.value)}
                      >
                        <option value="" disabled selected>
                          Select City
                        </option>
                        {formFieldsData.city.map((curElem, i) => {
                          return (
                            <option value={curElem.id}>
                              {curElem.city_name.toUpperCase()}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="invalid-feedback">
                      {errors?.city_id?.type === "required" &&
                        "City/Suburb Name is required"}
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <div className="form-group text-start">
                      <label class="mb-1">State/Territory</label>
                      <select
                        name="state"
                        id="state"
                        className="form-control form-select state"
                        value={formFieldsData.state_id}
                        {...register("state", {
                          required: false,
                        })}
                      >
                        <option value="" disabled selected>
                          State/Territory
                        </option>
                        {formFieldsData.state.map((curElem, i) => {
                          return (
                            <option value={curElem.id}>
                              {curElem.state_name.toUpperCase()}
                            </option>
                          );
                        })}
                      </select>
                      <div className="invalid-feedback">
                        {errors?.state?.type === "required" &&
                          " State/Territory Name is required"}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-between mb-3">
                  <NavLink
                    onClick={(e) => handleBackFetchApi(e, getformid)}
                    to={{
                      pathname: "/get-a-quote",
                    }}
                  >
                    <button className="btn btn-primary text-uppercase btn-md">
                      <IoArrowBackOutline fontSize="1.1em" /> Back
                    </button>
                  </NavLink>
                  <button
                    disabled={isSubmitting}
                    className="btn btn-primary text-uppercase btn-md"
                  >
                    {isSubmitting && (
                      <span className="spinner-border spinner-border-sm mr-1"></span>
                    )}
                    NEXT <IoArrowForwardOutline fontSize="1.1em" />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
