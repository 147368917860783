import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import BodyClassName from "react-body-classname";
import { toast } from "react-toastify";
import Config from "../../src/Config/Constants";
import { IoMailOutline } from "react-icons/io5";

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [errorList, setErrorList] = useState([]);
  const [errorClass, setErrorClass] = useState("hide");

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm();
  const { isSubmitting } = formState;

  const onSubmit = async (data, e) => {
    //let utoken = localStorage.getItem("Token");

    e.preventDefault();
    if (data) {
      const response = await fetch(
        Config.BASEURL + `/auth/password/forgot-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            //  Authorization: `Bearer ${utoken}`,
          },
          body: JSON.stringify(data),
        }
      );
      const responseData = await response.json();

      if (responseData?.success === true) {
        //localStorage.setItem("Email", responseData?.email);
        toast.success(responseData?.message, {
          position: "top-center",
          autoClose: 1000,
        });
        navigate("/", { replace: true });
        setErrorClass("hide");
      } else {
        var errorData = [];
        if (responseData?.data.error) {
          if (typeof responseData?.data.error === Object) {
            for (const [key, value] of Object.entries(
              responseData?.data.error
            )) {
              if (value.length > 0) {
                for (var i = 0; i < value.length; i++) {
                  if (errorData.indexOf(value[i]) < 0) {
                    errorData.push(value[i]);
                  }
                }
              }
            }
          } else {
            errorData.push(responseData?.data.error);
          }

          setErrorList(errorData);
          setErrorClass("show");
          setTimeout(() => {
            setErrorClass("hide");
          }, 5000);
        } else {
          setErrorList(errorData);
          setErrorClass("hide");
        }
      }
    }
  };
  return (
    <>
      <BodyClassName className="darkBg"></BodyClassName>
      <div className="d-flex align-items-center justify-content-center h-100">
        <div className="w-100">
          <div className="card h-100 shadow-sm rounded-5 overflow-hidden">
            <div className="row align-items-center">
              <div className="col-md-5">
                <div className="sidebarLeft">
                  <div className="sidebarLeftInner position-relative d-flex align-items-center justify-content-center flex-column">
                    <div className="logo-login">
                      <img
                        src="/images/logo-black.svg"
                        width="225"
                        alt="Moo-car"
                      />
                    </div>
                    <div className="carImage pt-2 pb-4">
                      <img src="/images/car.png" alt="Moo-car" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="frontForm">
                  <div
                    className={
                      "text-start alert alert-danger contentArea position-relative " +
                      errorClass
                    }
                  >
                    {errorList.length > 0
                      ? errorList.map((curElem, i) => {
                          return <>{curElem}</>;
                        })
                      : null}
                  </div>
                  <h2 className="m-0">Forgot Password?</h2>
                  <form
                    id="form"
                    className="flex flex-col mt-4 mb-4 p-3"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="form-group mb-3 position-relative fieldIcon">
                      <IoMailOutline fontSize="1.3em" />
                      <input
                        type="email"
                        className="form-control"
                        {...register("email", { required: true })}
                        placeholder="Email Address"
                        // value={email}
                        // onChange={(e) => setEmail(e.target.value)}
                      />
                      <div className="invalid-feedback mb-2">
                        {errors.email?.type === "required" &&
                          "Email is required"}
                      </div>
                    </div>
                    <div className="d-grid">
                      <button
                        disabled={isSubmitting}
                        className="btn btn-primary text-uppercase btn-md"
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-1"></span>
                        )}
                        Reset Password
                      </button>
                    </div>
                    <div className="forgot mt-2">
                      <a className="primary-colour" href="/">
                        Login?
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
