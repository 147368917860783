import React, { useEffect, useState } from "react";
import Config from "../Config/Constants";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
const Profile = () => {
  const Role_id = localStorage.getItem("Role_id");
  const Name = localStorage.getItem("Name");
  //const Email = localStorage.getItem("Email");
  const [errorList, setErrorList] = useState([]);
  const [errorClass, setErrorClass] = useState("hide");

  let utoken = localStorage.getItem("Token");
  const [formFieldsData, setFormFieldsData] = useState({
    city: [],
    state: [],
  });
  const getSelectOptions = async () => {
    const response = await fetch(Config.BASEURL + `/auth/get_quote_form_meta`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${utoken}`,
        Accept: "application/json",
      },
    });
    let parsedResponse = await response.json();
    if (parsedResponse?.success) {
      setFormFieldsData({
        ...formFieldsData,
        ...parsedResponse?.data,
      });
    }
  };

  const handleEditProfile = async () => {
    const response = await fetch(Config.BASEURL + `/auth/userprofile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${utoken}`,
        Accept: "application/json",
      },
      body: JSON.stringify(Role_id),
    });
    const responseStaffData = await response.json();
    //console.log(responseStaffData, "responseStaffData");
    if (responseStaffData?.success) {
      let data = responseStaffData?.data;
      reset({
        name: data?.name || [],
        email: data?.email || [],
        mobile: data?.mobile || [],
        empholder: data?.empholder || [],
        city_id: data?.city_id,
        city_name: data?.city_name || [],
        address: data?.address || [],
        address2: data?.address2 || [],
        state: data?.state || [],
        post_code: data?.post_code || [],
      });
    }
  };
  useEffect(() => {
    handleEditProfile();
    getSelectOptions();
  }, []);

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
    reset,
  } = useForm();
  const { isSubmitting } = formState;

  const onSubmit = async (data) => {
    let utoken = localStorage.getItem("Token");
    if (data) {
      const response = await fetch(Config.BASEURL + `/auth/userprofileupdate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${utoken}`,
        },
        body: JSON.stringify(data),
      });
      const responseData = await response.json();
      //console.log("result", responseData);
      if (responseData?.success === true) {
        toast.success(responseData?.message, {
          position: "top-center",
          autoClose: 1000,
        });
        setErrorClass("hide");
      } else {
        var errorData = [];
        if (responseData?.data) {
          for (const [key, value] of Object.entries(responseData?.data)) {
            if (value.length > 0) {
              for (var i = 0; i < value.length; i++) {
                if (errorData.indexOf(value[i]) < 0) {
                  errorData.push(value[i]);
                }
              }
            }
          }
          setErrorList(errorData);
          setErrorClass("show");
          setTimeout(() => {
            setErrorClass("hide");
          }, 5000);
        } else {
          setErrorList(errorData);
          setErrorClass("hide");
        }
      }
    }
  };

  return (
    <>
      <div className="row pt-lg-5 mt-3 contentArea position-relative justify-content-center">
        <div className="col-md-10">
          <div className="card h-100 shadow-sm rounded-5">
            <div className="card-header bg-transparent border-0 p-4 mt-3">
              <h2>Profile</h2>
            </div>
            <div
              className={
                "text-start alert alert-danger contentArea position-relative " +
                errorClass
              }
            >
              {errorList.length > 0
                ? errorList.map((curElem, i) => {
                    return <div>{curElem}</div>;
                  })
                : null}
            </div>
            <form id="form" onSubmit={handleSubmit(onSubmit)}>
              <div className="card-body pt-lg-2 p-lg-5">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <div className="form-group text-start">
                      <label class="mb-1">Dealer Name</label>
                      <input
                        type="name"
                        className="form-control"
                        {...register("name", {
                          required: true,
                          disabled: true,
                        })}
                        placeholder="Dealer Name"
                      />
                      <div className="invalid-feedback mb-2">
                        {errors.responseData?.name === "required" &&
                          "Dealer Name is required"}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group text-start">
                      <label class="mb-1">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        {...register("email", {
                          required: true,
                          disabled: true,
                        })}
                        placeholder="Email"
                      />
                      <div className="invalid-feedback mb-2">
                        {errors.responseData?.email === "required" &&
                          "Email is required"}
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-md-6 mb-3">
                    <div className="form-group text-start">
                      <label class="mb-1">Mobile Number</label>
                      <input
                        type="mobile"
                        className="form-control"
                        {...register("mobile", {
                          required: true,
                          disabled: true,
                        })}
                        placeholder="Mobile Number"
                      />
                      <div className="invalid-feedback mb-2">
                        {errors.responseData?.mobile === "required" &&
                          "Mobile Number is required"}
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="col-md-6 mb-3">
                    <div className="form-group text-start">
                      <label class="mb-1">Role ID</label>
                      <input
                        type="role_id"
                        className="form-control"
                        {...register("Role_id", { required: true,disabled: true })}
                        placeholder="Role ID"
                      />
                      <div className="invalid-feedback mb-2">
                        {errors.responseData?.Role_id === "required" &&
                          "Role ID is required"}
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="col-md-6 mb-3">
                    <div className="form-group text-start">
                      <label class="mb-1">Employer/ABN holder</label>
                      <input
                        type="empholder"
                        className="form-control"
                        {...register("empholder", {
                          required: true,
                          disabled: true,
                        })}
                        placeholder="Employer/ABN holder"
                      />
                      <div className="invalid-feedback mb-2">
                        {errors.responseData?.empholder === "required" &&
                          "Employer/ABN holder is required"}
                      </div>
                    </div>
                  </div> */}
                  <div className="col-md-6 mb-3">
                    <div className="form-group text-start">
                      <label class="mb-1">Address Line 1</label>
                      <input
                        type="address"
                        className="form-control"
                        {...register("address", {
                          required: true,
                          disabled: true,
                        })}
                        placeholder="Address"
                      />
                      <div className="invalid-feedback mb-2">
                        {errors.responseData?.address === "required" &&
                          "Address Line 1 is required"}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group text-start">
                      <label class="mb-1">Address Line 2</label>
                      <input
                        type="address2"
                        className="form-control"
                        {...register("address2", {
                          required: true,
                          disabled: true,
                        })}
                        placeholder="Address"
                      />
                      <div className="invalid-feedback mb-2">
                        {errors.responseData?.address === "required" &&
                          "Address Line 2 is required"}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group text-start">
                      <label className="mb-1">Postcode</label>
                      <input
                        className="form-control"
                        {...register("post_code", {
                          required: true,
                          disabled: true,
                        })}
                        placeholder="Postcode"
                      />
                      <div className="invalid-feedback">
                        {errors?.post_code?.type === "required" &&
                          "Post Code is required"}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group text-start">
                      <label class="mb-1">City/Suburb</label>
                      <input
                        type="city_name"
                        className="form-control"
                        {...register("city_name", {
                          required: true,
                          disabled: true,
                        })}
                        placeholder="City/Suburb"
                      />
                      <div className="invalid-feedback mb-2">
                        {errors.responseData?.city_name === "required" &&
                          "City/Suburb is required"}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-group text-start">
                      <label class="mb-1">State/Territory</label>
                      <input
                        type="state"
                        className="form-control"
                        {...register("state", {
                          required: true,
                          disabled: true,
                        })}
                        placeholder="State/Territory"
                      />
                      <div className="invalid-feedback mb-2">
                        {errors.responseData?.state === "required" &&
                          "State/Territory is required"}
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-md-6 mb-3">
                    <div className="form-group text-start">
                      <label class="mb-1">City/Suburb</label>

                      <select
                        name="city_id"
                        id="city_id"
                        className="form-control city_id"
                        value={formFieldsData.city_id}
                        {...register("city_id", {
                          required: false,
                          disabled: true,
                        })}
                      >
                        <option value="" disabled selected>
                          City/Suburb
                        </option>
                        {formFieldsData.city.map((curElem, i) => {
                          return (
                            <option value={curElem.id}>
                              {curElem.city_name}
                            </option>
                          );
                        })}
                      </select>
                      <div className="invalid-feedback">
                        {errors?.vehicle_city_id?.type === "required" &&
                          " City/Suburb Name is required"}
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="col-md-6 mb-3">
                    <div className="form-group text-start">
                      <label class="mb-1">State/Territory</label>
                      <select
                        name="state"
                        id="state"
                        className="form-control state"
                        value={formFieldsData.state_id}
                        {...register("state", {
                          required: false,
                          disabled: true,
                        })}
                      >
                        <option value="" disabled selected>
                          State/Territory
                        </option>
                        {formFieldsData.state.map((curElem, i) => {
                          return (
                            <option value={curElem.id}>
                              {curElem.state_name}
                            </option>
                          );
                        })}
                      </select>
                      <div className="invalid-feedback">
                        {errors?.state?.type === "required" &&
                          " State/Territory Name is required"}
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="mb-3 col-md-6">
                    <div className="form-group text-start">
                      <label className="mb-1">State/Territory</label>
                      <select
                        name="state_id"
                        id="state_id"
                        className="form-control form-select state_id"
                        value={formFieldsData.state}
                        {...register("state_id", { required: true })}
                      >
                        <option value="" disabled selected>
                          State/Territory
                        </option>
                        {formFieldsData.state.map((curElem, i) => {
                          return (
                            <option value={curElem.id}>
                              {curElem.state_name}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    <div className="invalid-feedback">
                      {errors?.state_id?.type === "required" &&
                        "State/Territory Name is required"}
                    </div>
                  </div> */}
                </div>
                {/* <button
                  disabled={isSubmitting}
                  className="btn btn-primary text-uppercase btn-md"
                >
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  UPDATE PROFILE
                </button> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
