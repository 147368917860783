import React, { useState, useRef } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import { BiPrinter } from "react-icons/bi";
import { useReactToPrint } from "react-to-print";
import Test from "../PrintDealerViewLead";

import "../../../src/scss/custom.css";
import { Link } from "react-router-dom";
// import Config from "../../Config/Constants";
// import { toast } from "react-toastify";

const ViewQuoteLead = React.forwardRef((props, ref) => {
  // const [SetFetchApi, setFetchLeadApi] = useState({});
  // const { lead_id } = useParams();
  // const setFetchApi = async () => {
  //   let utoken = localStorage.getItem("Token");
  //   const response = await fetch(
  //     Config.BASEURL + `/auth/fetch_single_lead_details`,
  //     {
  //       method: "POST",
  //       headers: {
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${utoken}`,
  //         Accept: "application/json",
  //       },
  //       body: JSON.stringify({ lead_id: lead_id }),
  //     }
  //   );
  //   const responseFetchLeadData = await response.json();
  //   //console.log(responseFetchLeadData);
  //   if (responseFetchLeadData?.success === true) {
  //     setFetchLeadApi({
  //       ...SetFetchApi,
  //       ...responseFetchLeadData?.data,
  //     });
  //   } else if (responseFetchLeadData?.success === false) {
  //     toast.error(responseFetchLeadData?.message, {
  //       position: "top-center",
  //       autoClose: 1000,
  //     });
  //   }
  // };
  // useEffect(() => {
  //   setFetchApi();
  // }, []);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <div className="row py-lg-5 my-3 contentArea position-relative justify-content-center">
        <div className="d-flex justify-content-between mb-3">
          <Link to="/lead-sent">
            <button className="btn btn-primary text-uppercase btn-md">
              <IoArrowBackOutline fontSize="1.1em" /> Back
            </button>
          </Link>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-primary text-uppercase btn-md"
              onClick={handlePrint}
            >
              <BiPrinter fontSize="1.5em" /> Print
            </button>
          </div>
        </div>
        <div className="col-xl-12">
          <Test ref={componentRef} />
        </div>
      </div>
    </>
  );
});

export default ViewQuoteLead;
