import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import BodyClassName from "react-body-classname";
import Config from "../Config/Constants";
import { IoPersonOutline } from "react-icons/io5";
import { IoLockClosedOutline } from "react-icons/io5";

export default function LoginForm() {
  const navigate = useNavigate();
  const [errorList, setErrorList] = useState([]);
  const [errorClass, setErrorClass] = useState("hide");

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm();
  const { isSubmitting } = formState;

  const onSubmit = async (data, e) => {
    e.preventDefault();
    if (data) {
      const response = await fetch(Config.BASEURL + `/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const responseData = await response.json();
      if (responseData?.success === true) {
        //console.log(responseData);
        localStorage.setItem("Token", responseData.data.access_token);
        localStorage.setItem("Email", data?.email);
        localStorage.setItem(
          "Staffpermission",
          responseData.data.staff_member_permission
        );
        localStorage.setItem("Role_id", responseData.data.role_id);
        ///localStorage.setItem("notify", responseData.notify);

        localStorage.setItem("Name", responseData.data.name);
        localStorage.setItem("parent_id", responseData.data.parent_id);
        //Role-2 (Sales -representative)
        if (responseData?.data.role_id === 2) {
          navigate("/get-a-quote", { replace: true });
          // Role -3 (Car - Dealership)
        } else if (responseData?.data.role_id === 3) {
          navigate("/lead-received", { replace: true });
          // Role -4 (Staff - Member)
        } else if (responseData?.data.role_id === 4) {
          navigate("/staff-quote-sent", { replace: true });
        }

        window.location.reload();
        setErrorClass("hide");
      } else {
        var errorData = [];
        if (responseData?.data.error) {
          if (typeof responseData?.data.error === Object) {
            for (const [key, value] of Object.entries(
              responseData?.data.error
            )) {
              if (value.length > 0) {
                for (var i = 0; i < value.length; i++) {
                  if (errorData.indexOf(value[i]) < 0) {
                    errorData.push(value[i]);
                  }
                }
              }
            }
          } else {
            errorData.push(responseData?.data.error);
          }

          setErrorList(errorData);
          setErrorClass("show");
          setTimeout(() => {
            setErrorClass("hide");
          }, 5000);
        } else {
          setErrorList(errorData);
          setErrorClass("hide");
        }
      }
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 2000);
    });
  };

  return (
    <>
      <BodyClassName className="darkBg"></BodyClassName>
      <div className="d-flex align-items-center justify-content-center h-100 loginBlock">
        <div className="w-100 py-5">
          <div className="card h-100 shadow-sm rounded-5 overflow-hidden">
            <div className="row align-items-center">
              <div className="col-md-5">
                <div className="sidebarLeft">
                  <div className="sidebarLeftInner position-relative d-flex align-items-center justify-content-center flex-column">
                    <div className="logo-login">
                      <img
                        src="images/logo-black.svg"
                        width="225"
                        alt="Moo-car"
                      />
                    </div>
                    <div className="carImage pt-2 pb-4">
                      <img src="images/car.png" alt="Moo-car" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-7">
                <div className="frontForm">
                  <h2 className="m-0">Welcome</h2>
                  <p className="primary-colour">Please login to continue</p>
                  <div
                    className={
                      "text-start alert alert-danger contentArea position-relative " +
                      errorClass
                    }
                  >
                    {errorList.length > 0
                      ? errorList.map((curElem, i) => {
                          return <>{curElem}</>;
                        })
                      : null}
                  </div>
                  <form
                    id="form"
                    className="flex flex-col mt-4 mb-4 p-3 loginForm"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="form-group mb-3 position-relative fieldIcon">
                      <IoPersonOutline fontSize="1.3em" />
                      <input
                        type="email"
                        className="form-control"
                        {...register("email", { required: true })}
                        placeholder="Email Address"
                      />
                      <div className="invalid-feedback mb-2">
                        {errors.email?.type === "required" &&
                          "Email is required"}
                      </div>
                    </div>
                    <div className="form-group mb-2 fieldIcon position-relative">
                      <IoLockClosedOutline fontSize="1.3em" />
                      <input
                        type="password"
                        className="form-control"
                        {...register("password", { required: true })}
                        placeholder="Password"
                      />
                      <div className="invalid-feedback mb-2">
                        {errors.password?.type === "required" &&
                          "Password is required"}
                      </div>
                    </div>
                    <div className="form-check form-group mb-4 text-start d-flex align-items-center loginCheckbox">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="inlineCheckbox1"
                        value="option1"
                      />
                      <label
                        className="form-check-label ml-2"
                        htmlFor="inlineCheckbox1"
                      >
                        Remember me
                      </label>
                    </div>
                    <div className="d-grid">
                      <button
                        disabled={isSubmitting}
                        className="btn btn-primary text-uppercase btn-md"
                      >
                        {isSubmitting && (
                          <span className="spinner-border spinner-border-sm mr-1"></span>
                        )}
                        Login
                      </button>
                    </div>
                    <div className="forgot mt-2">
                      <a className="primary-colour" href="/forgotpassword">
                        Forgot Password?
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
