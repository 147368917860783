import React, { useEffect, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import "../../../src/scss/custom.css";
import { Link, useParams } from "react-router-dom";
import Config from "../../Config/Constants";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
// import { confirmAlert } from "react-confirm-alert";

const ViewLead = () => {
  const newStatus = localStorage.getItem("new_status");
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [errorList, setErrorList] = useState([]);
  const [errorClass, setErrorClass] = useState("hide");
  const Role_id = localStorage.getItem("Role_id");
  const [SetFetchApi, setFetchLeadApi] = useState({});
  const { lead_id } = useParams();
  const navigate = useNavigate();
  const setFetchApi = async () => {
    let utoken = localStorage.getItem("Token");
    const response = await fetch(
      Config.BASEURL + `/auth/fetch_single_lead_details`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${utoken}`,
          Accept: "application/json",
        },
        body: JSON.stringify({ lead_id: lead_id }),
      }
    );
    const responseFetchLeadData = await response.json();
    if (responseFetchLeadData?.success === true) {
      localStorage.setItem(
        "new_status",
        responseFetchLeadData.data?.new_status
      );
      setFetchLeadApi({
        ...SetFetchApi,
        ...responseFetchLeadData?.data,
      });
    } else if (responseFetchLeadData?.success === false) {
      toast.error(responseFetchLeadData?.message, {
        position: "top-center",
        autoClose: 1000,
      });
    }
  };
  useEffect(() => {
    setFetchApi();
  }, []);

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm();
  const { isSubmitting } = formState;

  const onSubmit = async (data, e) => {
    const dealer_id = localStorage.getItem("Role_id");
    let utoken = localStorage.getItem("Token");
    e.preventDefault();
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${utoken}`);
    var formdata = new FormData();
    formdata.append("amount", data.amount);
    formdata.append("attachment", selectedFiles);
    formdata.append("dealer_id", dealer_id);
    formdata.append("notes", data?.notes || "");
    formdata.append("quote_id", lead_id);

    var requestOptions = await fetch(
      Config.BASEURL + `/auth/send_quote_amount_to_sales_person`,
      {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      }
    );
    const responseData = await requestOptions.json();
    if (responseData?.success === true) {
      toast.success(responseData?.message, {
        position: "top-center",
        autoClose: 1000,
      });
      navigate("/lead-received", { replace: true });
      setErrorClass("hide");
    } else {
      var errorData = [];
      if (responseData?.data) {
        for (const [key, value] of Object.entries(responseData?.data)) {
          if (value.length > 0) {
            for (var i = 0; i < value.length; i++) {
              if (errorData.indexOf(value[i]) < 0) {
                errorData.push(value[i]);
              }
            }
          } else {
            errorData.push(responseData?.data);
          }
        }
        setErrorList(errorData);
        setErrorClass("show");
        setTimeout(() => {
          setErrorClass("hide");
        }, 5000);
      } else {
        setErrorList(errorData);
        setErrorClass("hide");
      }
    }
  };
  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };

  const onUploadFileChange = ({ target }) => {
    if (target.files < 1 || !target.validity.valid) {
      return;
    }
    fileToBase64(target.files[0], (err, result) => {
      if (result) {
        setSelectedFiles(result);
        console.log(target);
      }
    });
  };

  return (
    <>
      {Role_id === "3" ? (
        <div className="row py-lg-5 my-3 contentArea position-relative justify-content-center">
          <div className="d-flex justify-content-start mb-3">
            <Link to="/lead-received">
              <button className="btn btn-primary text-uppercase btn-md">
                <IoArrowBackOutline fontSize="1.1em" /> Back
              </button>
            </Link>
          </div>
          <div className="d-flex justify-content-center">
            <div className="col-xl-12">
              <div className="card h-100 shadow-sm rounded-5">
                <div className="card-header bg-transparent border-0 p-4 mt-3">
                  <h2>Leads Details</h2>
                </div>
                <div className="card-body row">
                  <div class="col-xl-7">
                    <div class="table-responsive">
                      <table class="table border">
                        <thead className="container">
                          {SetFetchApi && (
                            <>
                              <tr>
                                <th
                                  align="left"
                                  className="col-3 mb-3 text-start"
                                >
                                  Lead Creator
                                </th>
                                <td align="left" className="col-md-6 mb-3">
                                  {SetFetchApi?.lead_created_by ?? ""}
                                </td>
                              </tr>

                              <tr>
                                <th
                                  align="left"
                                  className="col-3 mb-3 text-start"
                                >
                                  Vehicle Make
                                </th>
                                <td align="left" className="col-md-6 mb-3">
                                  {SetFetchApi?.make ?? ""}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  align="left"
                                  className="col-3 mb-3 text-start"
                                >
                                  Vehicle Model
                                </th>
                                <td align="left" className="col-md-6 mb-3">
                                  {SetFetchApi?.model ?? ""}
                                </td>
                              </tr>

                              <tr>
                                <th
                                  align="left"
                                  className="col-3 mb-3 text-start"
                                >
                                  Variant
                                </th>
                                <td align="left" className="col-md-6 mb-3">
                                  {SetFetchApi?.vehicle_variant_id ?? ""}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  align="left"
                                  className="col-3 mb-3 text-start"
                                >
                                  Vehicle Body Type
                                </th>
                                <td align="left" className="col-md-6 mb-3">
                                  {SetFetchApi?.body_type ?? ""}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  align="left"
                                  className="col-3 mb-3 text-start"
                                >
                                  Transmission Type
                                </th>
                                <td align="left" className="col-md-6 mb-3">
                                  {SetFetchApi?.vehicle_transmission_type_id ??
                                    ""}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  align="left"
                                  className="col-3 mb-3 text-start"
                                >
                                  Optional Options
                                </th>
                                <td align="left" className="col-md-6 mb-3">
                                  {SetFetchApi?.vehicle_optional_id ?? ""}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  align="left"
                                  className="col-3 mb-3 text-start"
                                >
                                  Vehicle Color
                                </th>
                                <td align="left" className="col-md-6 mb-3">
                                  {SetFetchApi?.vehicle_color ?? ""}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  align="left"
                                  className="col-3 mb-3 text-start"
                                >
                                  Vehicle Urgency
                                </th>
                                <td align="left" className="col-md-6 mb-3">
                                  {SetFetchApi?.urgency ?? ""}
                                </td>
                              </tr>

                              <tr>
                                <th
                                  align="left"
                                  className="col-3 mb-3 text-start"
                                >
                                  Purpose
                                </th>
                                <td align="left" className="col-md-6 mb-3">
                                  {SetFetchApi?.reg_number ?? ""}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  align="left"
                                  className="col-3 mb-3 text-start"
                                >
                                  City
                                </th>
                                <td align="left" className="col-md-6 mb-3">
                                  {SetFetchApi?.city ?? ""}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  align="left"
                                  className="col-3 mb-3 text-start"
                                >
                                  Sales Rep. Notes
                                </th>
                                <td align="left" className="col-md-6 mb-3">
                                  <div>
                                    <pre>{SetFetchApi?.sales_notes ?? ""}</pre>
                                  </div>
                                </td>
                              </tr>
                            </>
                          )}
                        </thead>
                      </table>
                    </div>
                  </div>
                  {newStatus == 1 ? (
                    <div className="col-md-5">
                      <h3>Send Quote</h3>
                      <div className="px-3">
                        <div
                          className={
                            "text-start alert alert-danger m-0 contentArea position-relative " +
                            errorClass
                          }
                        >
                          {errorList.length > 0
                            ? errorList.map((curElem, i) => {
                                return <>{curElem}</>;
                              })
                            : null}
                        </div>
                      </div>

                      <form id="form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group mb-3">
                          <input
                            type="number"
                            className="form-control"
                            {...register("amount", {
                              required: false,
                              minLength: 0,
                              // required: true,
                              // minLength: 2,
                              maxLength: 12,
                            })}
                            placeholder="Amount"
                          />
                        </div>
                        <div
                          className="form-group mb-3"
                          sx={{ textAlign: "left" }}
                        >
                          <input
                            type="file"
                            name="filetobase64"
                            accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                            onChange={onUploadFileChange}
                          />
                        </div>
                        <div className="form-group mb-3">
                          <textarea
                            type="textarea"
                            className="form-control quote-textarea"
                            {...register("notes")}
                            placeholder="Comment If Any"
                          />
                        </div>
                        <div class="d-grid">
                          <button
                            disabled={isSubmitting}
                            className="btn btn-primary text-uppercase btn-md"
                          >
                            {isSubmitting && (
                              <span className="spinner-border spinner-border-sm mr-1"></span>
                            )}
                            SUBMIT
                          </button>
                        </div>
                      </form>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        navigate("/lead-received", { replace: true })
      )}
    </>
  );
};

export default ViewLead;