import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "../../../src/scss/custom.css";
import { Link } from "react-router-dom";
import { IoArrowBackOutline } from "react-icons/io5";
import { useNavigate } from "react-router";
import Config from "../../Config/Constants";
import { toast } from "react-toastify";
const AddStaff = () => {
  const navigate = useNavigate();
  let utoken = localStorage.getItem("Token");
  const [errorList, setErrorList] = useState([]);
  const [errorClass, setErrorClass] = useState("hide");
  const [formFieldsData, setFormFieldsData] = useState({ city: [], state: [] });
  const getSelectOptions = async () => {
    const response = await fetch(Config.BASEURL + `/auth/get_quote_form_meta`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${utoken}`,
        Accept: "application/json",
      },
    });
    let parsedResponse = await response.json();
    if (parsedResponse?.success) {
      setFormFieldsData({
        ...formFieldsData,
        ...parsedResponse?.data,
      });
    } else {
    }
  };
  useEffect(() => {
    getSelectOptions();
  }, []);

  const {
    register,
    handleSubmit,
    formState,
    formState: { errors },
  } = useForm();
  const { isSubmitting } = formState;

  const onSubmit = async (data, e) => {
    e.preventDefault();
    if (data) {
      const response = await fetch(
        Config.BASEURL + `/auth/create_staff_by_dealer`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${utoken}`,
          },
          body: JSON.stringify(data),
        }
      );

      const responseData = await response.json();
      if (responseData?.success === true) {
        toast.success(responseData?.message, {
          position: "top-center",
          autoClose: 1000,
        });
        navigate("/staffMember", { replace: true });
        setErrorClass("hide");
      } else {
        var errorData = [];
        if (responseData?.data) {
          for (const [key, value] of Object.entries(responseData?.data)) {
            if (value.length > 0) {
              for (var i = 0; i < value.length; i++) {
                if (errorData.indexOf(value[i]) < 0) {
                  errorData.push(value[i]);
                }
              }
            }
          }
          setErrorList(errorData);
          setErrorClass("show");
          setTimeout(() => {
            setErrorClass("hide");
          }, 5000);
        } else {
          setErrorList(errorData);
          setErrorClass("hide");
        }
      }
    }
  };
  return (
    <>
      <div className="row py-lg-5 my-3 contentArea position-relative">
        <div className="d-flex justify-content-start mb-3">
          <Link to="/staffMember">
            <button className="btn btn-primary text-uppercase btn-md">
              <IoArrowBackOutline fontSize="1.1em" /> Back
            </button>
          </Link>
        </div>
        <div className="col-12">
          <div className="card h-100 shadow-sm rounded-5">
            <div className="card-header bg-transparent border-0 p-4 mt-3">
              <h2>Add Staff</h2>
            </div>
            <div className="card-body pt-2 p-5">
              <div
                className={
                  "text-start alert alert-danger contentArea position-relative " +
                  errorClass
                }
              >
                {errorList.length > 0
                  ? errorList.map((curElem, i) => {
                      return <div>{curElem}</div>;
                    })
                  : null}
              </div>
              <form id="form" onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-6 mb-3 text-start">
                    <label class="mb-1">Staff Name</label>
                    <input
                      type="staffname"
                      className="form-control"
                      {...register("name", { required: true })}
                      placeholder="Staff Name"
                    />
                    <div className="invalid-feedback mb-2">
                      {errors.name?.type === "required" &&
                        "Staff Name is required"}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3 text-start">
                    <label class="mb-1">Email</label>
                    <input
                      type="Email"
                      className="form-control"
                      {...register("email", { required: true })}
                      placeholder="email"
                    />
                    <div className="invalid-feedback mb-2">
                      {errors.email?.type === "required" && "Email is required"}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3 text-start">
                    <label class="mb-1">Mobile Number</label>
                    <input
                      type="mobile"
                      className="form-control"
                      {...register("mobile", { required: true })}
                      placeholder="Mobile Number"
                    />
                    <div className="invalid-feedback mb-2">
                      {errors.mobile?.type === "required" &&
                        "Mobile Number is required"}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3 text-start">
                    <label class="mb-1">Address Line 1</label>
                    <input
                      type="address"
                      className="form-control"
                      {...register("address", { required: true })}
                      placeholder="Address Line 1"
                    />
                    <div className="invalid-feedback mb-2">
                      {errors.address?.type === "required" &&
                        "Address Line 1 is required"}
                    </div>
                  </div>

                  <div className="col-md-6 mb-3 text-start">
                    <label class="mb-1">Address Line 2</label>
                    <input
                      type="address2"
                      className="form-control"
                      {...register("address2", { required: false })}
                      placeholder="Address Line 2"
                    />
                    <div className="invalid-feedback mb-2">
                      {errors.address2?.type === "required" &&
                        "Address Line 2 is required"}
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <div className="form-group text-start">
                      <label className="mb-1">Postcode</label>
                      <input
                        className="form-control"
                        {...register("post_code", {
                          required: true,
                        })}
                        placeholder="Postcode"
                      />
                      <div className="invalid-feedback">
                        {errors?.post_code?.type === "required" &&
                          "Zip Code is required"}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3 text-start">
                    <label class="mb-1">City/Suburb</label>
                    <select
                      defaultValue=""
                      name="city_id"
                      id="city_id"
                      className="form-control form-select city_id"
                      value={formFieldsData.city_id}
                      {...register("city_id", { required: true })}
                    >
                      <option value="" disabled>
                        City/Suburb
                      </option>
                      {formFieldsData.city.map((curElem, i) => {
                        return (
                          <option value={curElem.id}>
                            {curElem.city_name}
                          </option>
                        );
                      })}
                    </select>
                    <div className="invalid-feedback">
                      {errors?.vehicle_city_id?.type === "required" &&
                        "City/Suburb is required"}
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <div className="form-group text-start">
                      <label class="mb-1">State/Territory</label>
                      <select
                        name="state"
                        defaultValue=""
                        id="state"
                        className="form-control form-select state"
                        value={formFieldsData.state_id}
                        {...register("state", {
                          required: false,
                        })}
                      >
                        <option value="" disabled>
                          State/Territory
                        </option>
                        {formFieldsData.state.map((curElem, i) => {
                          return (
                            <option value={curElem.id}>
                              {curElem.state_name}
                            </option>
                          );
                        })}
                      </select>
                      <div className="invalid-feedback">
                        {errors?.state?.type === "required" &&
                          " State/Territory Name is required"}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 mb-3 text-start">
                    <label class="mb-1">Password</label>
                    <input
                      type="Password"
                      className="form-control"
                      {...register("password", { required: true })}
                      placeholder="Password"
                    />
                    <div className="invalid-feedback mb-2">
                      {errors.password?.type === "required" &&
                        "Password is required"}
                    </div>
                  </div>
                  <div className="col-md-6 mb-3 text-start">
                    <label class="mb-1">Confirm Password</label>
                    <input
                      type="password"
                      className="form-control"
                      {...register("password_confirmation", { required: true })}
                      placeholder="Confirm Password"
                    />
                    <div className="invalid-feedback mb-2">
                      {errors.password_confirmation?.type === "required" &&
                        "Confirm Password is required"}
                    </div>
                  </div>
                </div>
                <button
                  disabled={isSubmitting}
                  className="btn btn-primary text-uppercase btn-md"
                >
                  {isSubmitting && (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  )}
                  SUBMIT
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddStaff;
