import React, { useEffect, useState } from "react";
import Sidebar from "../Sidebar";
import { Controller, useForm } from "react-hook-form";
import { IoArrowForwardOutline } from "react-icons/io5";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
// import { Link, useParams } from "react-router-dom";
import Config from "../../Config/Constants";
import Multiselect from "multiselect-react-dropdown";
const Role_id = localStorage.getItem("Role_id");
let utoken = localStorage.getItem("Token");

const GetAQuotes = () => {
  const navigate = useNavigate();
  const [selectMake, setSelectMake] = useState("");
  const [selectModel, setSelectModel] = useState("");
  const [selectVariant, setSelectVariant] = useState("");
  const [selectBody, setSelectBody] = useState();
  const [selectTransmission, setSelectTransmission] = useState();
  const [selectOptional, setSelectOptional] = useState(); // for all values
  const [selectMakeOptions, setSelectMakeOptions] = useState();
  const [selectModelOptions, setSelectModelOptions] = useState([]);
  const [selectVariantOptions, setSelectVariantOptions] = useState([]);
  const [selectBodyOptions, setSelectBodyOptions] = useState([]);
  const [selectTransmissionOptions, setSelectTransmissionOptions] = useState(
    []
  );
  const [selectOptionalOptions, setSelectOptionalOptions] = useState([]); // for selected values
  const [errorList, setErrorList] = useState([]);
  const [errorClass, setErrorClass] = useState("hide");

  const [formFieldsData, setFormFieldsData] = useState({
    body_type: [],
    city: [],
    color: [],
    sales_notes: [],
    drive_type: [],
    genuine_accessory: [],
    make: [],
    transmission_type: [],
    variant: [],
    optional: [],
    package: [],
    register_number: [],
    urgencey: [],
    models: [],
  });
  const {
    register,
    handleSubmit,
    control,
    formState,
    resetField,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      vehicle_model_id: "",
      vehicle_variant_id: "",
      vehicle_body_type_id: "",
      vehicle_transmission_type_id: "",
      vehicle_optional_id: "",
    },
  });
  const { isSubmitting } = formState;

  const setGetaQuoteFetchApi = async (fieldsData) => {
    console.log("fieldsData on fetch", fieldsData);
    const id = localStorage.getItem("LatestID");
    const response = await fetch(
      Config.BASEURL + `/auth/fetch_recent_created_lead`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${utoken}`,
          Accept: "application/json",
        },
        body: JSON.stringify({ id: id }),
      }
    );
    const responseStaffData = await response.json();

    // console.log({ responseStaffData });

    if (responseStaffData?.success) {
      let data = responseStaffData?.data;

      if (data?.vehicle_make_id) {
        await setSelectMake(data?.vehicle_make_id);
        await handleChange(data?.vehicle_make_id);
      }
      if (data?.vehicle_model_id) {
        await setSelectModel(data?.vehicle_model_id);
        await handleModelChange(data?.vehicle_model_id, data?.vehicle_make_id);
      }

      if (data?.vehicle_variant_id) {
        await setSelectVariant(data?.vehicle_variant_id);
        await handleVariantChange(
          data?.vehicle_variant_id,
          data?.vehicle_model_id,
          data?.vehicle_make_id
        );
      }
      if (data?.vehicle_body_type_id) {
        await setSelectBody(data?.vehicle_body_type_id);
        await handleBodyChange(
          data?.vehicle_body_type_id,
          data?.vehicle_make_id,
          data?.vehicle_model_id,
          data?.vehicle_variant_id
        );
      }
      if (data?.vehicle_transmission_type_id) {
        await setSelectTransmission(data?.vehicle_transmission_type_id);
        await handleTransmissionChange(
          data?.vehicle_transmission_type_id,
          data?.vehicle_make_id,
          data?.vehicle_model_id,
          data?.vehicle_variant_id,
          data?.vehicle_body_type_id,
          data?.vehicle_optional_id
        );
      }
      if (fieldsData?.vehicle_optional_id && data?.vehicle_optional_id) {
        let selectedOptions = fieldsData?.vehicle_optional_id?.filter((item) =>
          data?.vehicle_optional_id?.split(",").includes(item.id)
        );
        setSelectOptionalOptions(selectedOptions);
        resetField("vehicle_optional_id");
      }

      reset({
        vehicle_make_id: data?.vehicle_make_id || "",
        vehicle_model_id: data?.vehicle_model_id || "",
        vehicle_variant_id: data?.vehicle_variant_id || "",
        vehicle_body_type_id: data?.vehicle_body_type_id || "",
        vehicle_transmission_type_id: data?.vehicle_transmission_type_id || "",
        vehicle_optional_id: data?.vehicle_optional_id || "",
        vehicle_color: data?.vehicle_color || "",
        sales_notes: data?.sales_notes || "",
        vehicle_urgenecy_id: data?.vehicle_urgenecy_id || "",
        vehicle_registration_id: data?.vehicle_registration_id || "",
        vehicle_package_type_id: data?.vehicle_package_type_id || "",
        id: data?.id || "",
      });
    }
  };

  const getSelectOptions = async () => {
    const id = localStorage.getItem("LatestID");
    const response = await fetch(Config.BASEURL + `/auth/get_quote_form_meta`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${utoken}`,
        Accept: "application/json",
      },
    });
    let parsedResponse = await response.json();
    //console.log("parsedResponse", parsedResponse);
    setSelectMakeOptions(parsedResponse.data["make"]);
    setSelectModelOptions(parsedResponse.data["model"]);
    setSelectVariantOptions(parsedResponse.data["variant"]);
    setSelectBodyOptions(parsedResponse.data["body"]);
    setSelectTransmissionOptions(parsedResponse.data["transmission"]);
    setSelectOptional(parsedResponse.data["optional"]);
    if (parsedResponse?.success) {
      setFormFieldsData({
        ...formFieldsData,
        ...parsedResponse?.data,
      });
      if (id) {
        setGetaQuoteFetchApi(parsedResponse?.data);
      }
    }
  };

  useEffect(() => {
    getSelectOptions();
  }, []);

  const handleChange = async (value) => {
    setSelectMake(value);

    let utoken = localStorage.getItem("Token");
    const response = await fetch(
      Config.BASEURL + `/auth/get_vehicle_model_by_make`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${utoken}`,
          Accept: "application/json",
        },
        body: JSON.stringify({ vehicle_make_id: value }),
      }
    );
    let changeResponse = await response.json();
    if (changeResponse?.success) {
      setSelectModelOptions(changeResponse.data);
      setSelectModel("");
      resetField("vehicle_model_id");
      setSelectVariant("");
      resetField("vehicle_variant_id");
      setSelectBody("");
      resetField("vehicle_body_type_id");
      setSelectTransmission("");
      resetField("vehicle_transmission_type_id");
      setSelectOptionalOptions([]);
      resetField("vehicle_optional_id");
    }
  };

  const handleModelChange = async (value, make) => {
    setSelectModel(value);
    let utoken = localStorage.getItem("Token");
    const response = await fetch(
      Config.BASEURL +
        `/auth/fetch_all_variant_for_vehicle?vehicle_make_id=${
          selectMake || make
        }&vehicle_model_id=${value}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${utoken}`,
          Accept: "application/json",
        },
      }
    );
    let changeResponse = await response.json();
    if (changeResponse?.success) {
      setSelectVariantOptions(changeResponse.data);
      setSelectVariant("");
      resetField("vehicle_variant_id");
      setSelectBody("");
      resetField("vehicle_body_type_id");
      setSelectTransmission("");
      resetField("vehicle_transmission_type_id");
      setSelectOptionalOptions([]);
      resetField("vehicle_optional_id");
    }
  };

  const handleVariantChange = async (value, model, make) => {
    setSelectVariant(value);

    let utoken = localStorage.getItem("Token");
    const response = await fetch(
      Config.BASEURL +
        `/auth/fetch_all_body_types_for_vehicle?vehicle_make_id=${
          selectMake || make
        }&vehicle_model_id=${selectModel || model}&vehicle_variant_id=${value}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${utoken}`,
          Accept: "application/json",
        },
      }
    );
    let changeResponse = await response.json();
    if (changeResponse?.success) {
      setSelectBodyOptions(changeResponse.data);
      setSelectTransmission("");
      resetField("vehicle_transmission_type_id");
      setSelectOptionalOptions([]);
      resetField("vehicle_optional_id");
      setSelectBody("");
      resetField("vehicle_body_type_id");
    }
  };
  const handleBodyChange = async (value, make, model, variant) => {
    setSelectBody(value);
    let utoken = localStorage.getItem("Token");
    const response = await fetch(
      Config.BASEURL +
        `/auth/fetch_all_transmission_type_for_vehicle?vehicle_make_id=${
          selectMake || make
        }&vehicle_model_id=${selectModel || model}&vehicle_variant_id=${
          selectVariant || variant
        }&vehicle_body_type_id=${value}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${utoken}`,
          Accept: "application/json",
        },
      }
    );
    let changeResponse = await response.json();
    if (changeResponse?.success) {
      setSelectTransmissionOptions(changeResponse.data);
      setSelectOptionalOptions([]);
      resetField("vehicle_optional_id");
      setSelectTransmission("");
      resetField("vehicle_transmission_type_id");
    }
  };
  const handleTransmissionChange = async (
    value,
    make,
    model,
    variant,
    body,
    vehicleOption
  ) => {
    setSelectTransmission(value);

    let utoken = localStorage.getItem("Token");
    const response = await fetch(
      Config.BASEURL +
        `/auth/fetch_all_option_code_for_vehicle?vehicle_make_id=${
          selectMake || make
        }&vehicle_model_id=${selectModel || model}&vehicle_variant_id=${
          selectVariant || variant
        }&vehicle_body_type_id=${
          selectBody || body
        }&vehicle_transmission_type_id=${value}`,

      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${utoken}`,
          Accept: "application/json",
        },
      }
    );
    let changeResponse = await response.json();
    if (changeResponse?.success) {
      //setSelectOptional([]);
      // resetField("vehicle_optional_id");
      let selectedData = changeResponse.data.map((item) => {
        return {
          id: item.id,
          option_description: item.option_description,
        };
      });
      setSelectOptional(selectedData);

      if (vehicleOption) {
        let vehicleOptionList = vehicleOption.split(",");

        let filterSelectedData = selectedData.filter((item) =>
          vehicleOptionList.includes(item.id.toString())
        );
        setSelectOptionalOptions(filterSelectedData);
      }
    }
  };

  const handleOptionalChange = async (value) => {
    setSelectOptionalOptions(value);
  };

  const onSubmit = async (data) => {
    const LatestID = localStorage.getItem("LatestID"); // on next button click after confirm back id is passing
    // const cus_id = localStorage.getItem("cus_id");

    let utoken = localStorage.getItem("Token");

    let vehicleOptionalIds = selectOptionalOptions.map((item) => item?.id);
    let formdata = {
      ...data,
      vehicle_optional_id: vehicleOptionalIds,
    };
    //console.log("formdata submit ---", formdata);

    const response = await fetch(
      Config.BASEURL + `/auth/get_quote_form_meta_create`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${utoken}`,
          Accept: "application/json",
        },
        body: JSON.stringify(formdata, { id: LatestID }),
      }
    );
    const responseData = await response.json();

    if (responseData?.success === true) {
      localStorage.setItem(
        "LatestID",
        responseData.data.id ? responseData.data.id : LatestID
      );
      localStorage.setItem("Make", responseData.data.vehicle_make_id);
      toast.success("Your GetAQuotes Form Submitted Successfully", {
        position: "top-center",
        autoClose: 1000,
      });
      navigate("/customerdetails", { replace: true });
      setErrorClass("hide");
    } else {
      var errorData = [];
      if (responseData?.data) {
        for (const [key, value] of Object.entries(responseData?.data)) {
          if (value.length > 0) {
            for (var i = 0; i < value.length; i++) {
              if (errorData.indexOf(value[i]) < 0) {
                errorData.push(value[i]);
              }
            }
          }
        }
        setErrorList(errorData);
        setErrorClass("show");
        setTimeout(() => {
          setErrorClass("hide");
        }, 5000);
      } else {
        setErrorList(errorData);
        setErrorClass("hide");
      }
    }
  };

  return (
    <>
      {Role_id === "2" ? (
        <div className="row py-lg-5 my-3 contentArea position-relative">
          <div className="col-lg-2">
            <Sidebar />
          </div>
          <div className="col-lg-10">
            <div className="card h-100 shadow-sm rounded-5">
              <div className="card-header bg-transparent border-0 p-4 mt-3">
                <h2>GET A QUOTE </h2>
              </div>
              {/* error message*/}
              <div className="card-body pt-lg-2 p-lg-5">
                <div
                  className={
                    "contentArea text-start alert alert-danger position-relative " +
                    errorClass
                  }
                >
                  {errorList.length > 0
                    ? errorList.map((curElem, i) => {
                        return <div>{curElem}</div>;
                      })
                    : null}
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="mb-3 col-md-6 text-start">
                      <label className="mb-1">
                        Select Car Make (Brand/Manufacturer)
                      </label>
                      <Controller
                        control={control}
                        name="vehicle_make_id"
                        render={({ field: { onChange, value } }) => (
                          <select
                            defaultValue=""
                            name="vehicle_make_id"
                            id="vehicle_make_id"
                            onChange={(e) => {
                              onChange(e.target.value);
                              handleChange(e.target.value);
                            }}
                            className="form-control form-select vehicle_make_id"
                            value={value}
                          >
                            <option value="" disabled>
                              Select Car Make (Brand/Manufacturer)
                            </option>
                            {selectMakeOptions &&
                              selectMakeOptions.map((curElem, i) => {
                                return (
                                  <option
                                    onChange={(e) =>
                                      handleChange(e.target.value)
                                    }
                                    value={curElem.id}
                                  >
                                    {curElem.slug.toUpperCase()}
                                  </option>
                                );
                              })}
                          </select>
                        )}
                      />

                      <div className="invalid-feedback">
                        {errors?.make?.type === "required" &&
                          "Car Make (Brand/Manufacturer) is required"}
                      </div>
                    </div>

                    <div className="mb-3 col-md-6 text-start">
                      <label className="mb-1">Select Car Model</label>
                      <Controller
                        control={control}
                        name="vehicle_model_id"
                        render={({ field: { onChange, value } }) => (
                          <select
                            defaultValue=""
                            name="vehicle_model_id"
                            id="vehicle_model_id"
                            onChange={(e) => {
                              onChange(e.target.value);
                              handleModelChange(e.target.value);
                            }}
                            className="form-control form-select vehicle_model_id"
                            value={value}
                          >
                            <option value="" disabled>
                              Select Car Model
                            </option>
                            {selectModelOptions &&
                              selectModelOptions.map((curElem, i) => {
                                return (
                                  <option
                                    onChange={(e) =>
                                      handleModelChange(e.target.value)
                                    }
                                    value={curElem.id}
                                  >
                                    {curElem.slug.toUpperCase()}
                                  </option>
                                );
                              })}
                          </select>
                        )}
                      />
                      <div className="invalid-feedback">
                        {errors?.vehicle_model_id?.type === "required" &&
                          "Car Model is required"}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-6 text-start">
                      <label className="mb-1">Select Variant</label>
                      <Controller
                        control={control}
                        name="vehicle_variant_id"
                        render={({ field: { onChange, value } }) => (
                          <select
                            defaultValue=""
                            name="vehicle_variant_id"
                            id="vehicle_variant_id"
                            onChange={(e) => {
                              onChange(e.target.value);
                              handleVariantChange(e.target.value);
                            }}
                            className="form-control form-select vehicle_variant_id"
                            value={value}
                          >
                            <option value="" disabled>
                              Select Variant
                            </option>
                            {selectVariantOptions &&
                              selectVariantOptions.map((curElem, i) => {
                                return (
                                  <option
                                    onChange={(e) =>
                                      handleVariantChange(e.target.value)
                                    }
                                    value={curElem.id}
                                  >
                                    {curElem.slug.toUpperCase()}
                                  </option>
                                );
                              })}
                          </select>
                        )}
                      />
                      <div className="invalid-feedback">
                        {errors?.vehicle_variant_id?.type === "required" &&
                          "Varient is required"}
                      </div>
                    </div>
                    <div className="mb-3 col-md-6 text-start">
                      <label className="mb-1">Select Body Type</label>
                      <Controller
                        control={control}
                        name="vehicle_body_type_id"
                        render={({ field: { onChange, value } }) => (
                          <select
                            defaultValue=""
                            name="vehicle_body_type_id"
                            id="vehicle_body_type_id"
                            onChange={(e) => {
                              onChange(e.target.value);
                              handleBodyChange(e.target.value);
                            }}
                            className="form-control form-select vehicle_body_type_id"
                            value={value}
                          >
                            <option value="" disabled>
                              Select Body Type
                            </option>
                            {selectBodyOptions &&
                              selectBodyOptions.map((curElem, i) => {
                                return (
                                  <option
                                    onChange={(e) =>
                                      handleBodyChange(e.target.value)
                                    }
                                    value={curElem.id}
                                  >
                                    {curElem.body_name.toUpperCase()}
                                  </option>
                                );
                              })}
                          </select>
                        )}
                      />
                      <div className="invalid-feedback">
                        {errors?.vehicle_body_type_id?.type === "required" &&
                          "Body Type is required"}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="mb-3 col-md-6 text-start">
                      <label className="mb-1">Select Transmission Type</label>
                      <Controller
                        control={control}
                        name="vehicle_transmission_type_id"
                        render={({ field: { onChange, value } }) => (
                          <select
                            defaultValue=""
                            name="vehicle_transmission_type_id"
                            id="vehicle_transmission_type_id"
                            onChange={(e) => {
                              onChange(e.target.value);
                              handleTransmissionChange(e.target.value);
                            }}
                            className="form-control form-select vehicle_transmission_type_id"
                            value={value}
                          >
                            <option value="" disabled>
                              Select Transmission Type
                            </option>
                            {selectTransmissionOptions &&
                              selectTransmissionOptions.map((curElem, i) => {
                                return (
                                  <option
                                    onChange={(e) =>
                                      handleTransmissionChange(e.target.value)
                                    }
                                    value={curElem.id}
                                  >
                                    {curElem.slug.toUpperCase()}
                                  </option>
                                );
                              })}
                          </select>
                        )}
                      />
                      <div className="invalid-feedback">
                        {errors?.vehicle_transmission_type_id?.type ===
                          "required" && "Body Type is required"}
                      </div>
                    </div>
                    <div className="mb-3 col-md-6 text-start upper">
                      <label className="mb-1">Optional Options</label>
                      <Controller
                        control={control}
                        name="vehicle_optional_id"
                        render={({ field: { onChange, value } }) => (
                          <Multiselect
                            displayValue="option_description"
                            onSelect={(value) => {
                              onChange(value);
                              handleOptionalChange(value);
                            }}
                            options={selectOptional}
                            selectedValues={selectOptionalOptions}
                            placeholder="Optional Options"
                            name="vehicle_optional_id"
                            id="vehicle_optional_id"
                            className="form-control form-select vehicle_optional_id"
                          />
                        )}
                      />
                      <div className="invalid-feedback">
                        {errors?.vehicle_optional_id?.type === "required" &&
                          "Car optional options is required"}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-6 text-start">
                      <label className="mb-1">Colour</label>
                      <input
                        type="text"
                        className="form-control"
                        {...register("vehicle_color", { required: false })}
                        placeholder="Colour"
                      />
                      <div className="invalid-feedback">
                        {errors?.vehicle_color?.type === "required" &&
                          "Colour is required"}
                      </div>
                    </div>
                    <div className="mb-3 col-md-6 text-start">
                      <label className="mb-1">Select Urgency</label>
                      <select
                        defaultValue=""
                        name="vehicle_urgenecy_id"
                        id="vehicle_urgeney_id"
                        className="form-control form-select vehicle_urgenecy_id"
                        value={formFieldsData.vehicle_urgenecy_id}
                        {...register("vehicle_urgenecy_id", { required: true })}
                      >
                        <option value="" disabled>
                          Select Urgency
                        </option>
                        {formFieldsData.urgencey.map((curElem, i) => {
                          return (
                            <option value={curElem.id}>
                              {curElem.name.toUpperCase()}
                            </option>
                          );
                        })}
                      </select>
                      <div className="invalid-feedback">
                        {errors?.vehicle_urgenecy_id?.type === "required" &&
                          "Urgency is required"}
                      </div>
                    </div>
                    <div className="mb-3 col-md-6 text-start">
                      <label className="mb-1">Select Purpose</label>
                      <select
                        defaultValue=""
                        name="vehicle_registration_id"
                        id="vehicle_registration_id"
                        className="form-control form-select vehicle_registration_id"
                        value={formFieldsData.vehicle_registration_id}
                        {...register("vehicle_registration_id", {
                          required: true,
                        })}
                      >
                        <option value="" disabled>
                          Select Purpose
                        </option>
                        {formFieldsData.register_number.map((curElem, i) => {
                          return (
                            <option value={curElem.id}>
                              {curElem.name.toUpperCase()}
                            </option>
                          );
                        })}
                      </select>
                      <div className="invalid-feedback">
                        {errors?.vehicle_registration_id?.type === "required" &&
                          "Purpose is required"}
                      </div>
                    </div>
                    <div className="mb-3 col-md-6 text-start">
                      <label className="mb-1">Sales Rep. Notes</label>
                      <textarea
                        type="textarea"
                        className="form-control quote-textarea"
                        {...register("sales_notes", { required: false })}
                        placeholder="Comment If Any"
                      />
                      <div className="invalid-feedback">
                        {errors?.sales_notes?.type === "required" &&
                          "Sales Rep. Notes is required"}
                      </div>
                    </div>
                  </div>
                  <button
                    disabled={isSubmitting}
                    type="submit"
                    className="btn btn-primary text-uppercase btn-md"
                  >
                    {isSubmitting && (
                      <span className="spinner-border spinner-border-sm mr-1"></span>
                    )}
                    NEXT <IoArrowForwardOutline fontSize="1.1em" />
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      ) : (
        // (toast.success("UnAuthorized User", {
        //   position: "top-center",
        //   autoClose: 1000,
        // }),
        navigate("/profile", { replace: true })
      )}
    </>
  );
};

export default GetAQuotes;