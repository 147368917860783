import React from "react";
import { Route, Routes } from "react-router-dom";
import { ForgotPassword } from "../Login/ForgotPassword";
import LoginForm from "../Login/LoginForm";
import Profile from "../Login/Profile";
//import Navbar from "../Components/Navbar";
//import SalesRepresentativeProfile from "../Components/Sales Representative/SalesRepresentativeProfile";
import CarDealership from "../Components/CarDealership/CarDealership";
import StaffQuoteSent from "../Components/Staff User/Staff-Quote-sent";
import ResetPassword from "../Login/ResetPassword";
import Customerdetails from "../Components/Sales Representative/Customerdetails";
import GetAQuotes from "../Components/Sales Representative/GetAQuotes";
import Quotationlist from "../Components/Sales Representative/Quotationlist";
import ChooseDealers from "../Components/Sales Representative/ChooseDealers";
import ProtectedRoute from "./Protectedroute";
import PublicRoute from "./PublicRoute";
import Submitquote from "../Components/Sales Representative/Submitquote";
import StaffMember from "../Components/CarDealership/StaffMember";
import LeadReceived from "../Components/CarDealership/LeadReceived";
import QuoteSent from "../Components/CarDealership/QuoteSent";
import AddStaff from "../Components/CarDealership/AddStaff";
import UpdateStaff from "../Components/CarDealership/UpdateStaff";
import SendQuote from "../Components/CarDealership/SendQuote";
import ViewLead from "../Components/CarDealership/ViewLead";
import StaffSendQuote from "../Components/Staff User/StaffSendQuote";
import StaffViewLead from "../Components/Staff User/StaffViewLead";
import ViewQuoteLead from "../Components/CarDealership/ViewQuoteLead";
import ViewDealList from "../Components/Sales Representative/ViewDealList";
import Notification from "../Components/Notification";
import Print from "../Components/Print";

const Router = () => {
  return (
    <Routes>
      {/**
       *  Public Routes
       */}
      <Route element={<PublicRoute />}>
        <Route path="/" element={<LoginForm />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword/:token" element={<ResetPassword />} />

        {/* <Route path="/navbar" element={<Navbar />} /> */}
      </Route>

      {/**
       *  Protected Routes
       */}
      <Route element={<ProtectedRoute />}>
        <Route path="/profile" element={<Profile />} />
        <Route path="/cardealership" element={<CarDealership />} />
        <Route path="/staff-quote-sent" element={<StaffQuoteSent />} />
        <Route path="/customerdetails/" element={<Customerdetails />} />
        <Route path="/get-a-quote/" element={<GetAQuotes />} />
        <Route path="/deals-list" element={<Quotationlist />} />
        <Route path="/choosedealers" element={<ChooseDealers />} />
        <Route path="/submitquote" element={<Submitquote />} />
        <Route path="/staffmember" element={<StaffMember />} />
        <Route path="/lead-received" element={<LeadReceived />} />
        <Route path="/lead-sent" element={<QuoteSent />} />
        <Route path="/add-staff" element={<AddStaff />} />
        <Route path="/update-staff/:id" element={<UpdateStaff />} />
        <Route path="/sent-quote/:lead_id" element={<SendQuote />} />
        <Route path="/view-lead/:lead_id" element={<ViewLead />} />
        <Route path="/staff-sent-quote/:lead_id" element={<StaffSendQuote />} />
        <Route path="/staff-view-lead/:lead_id" element={<StaffViewLead />} />
        <Route path="/view-quote-lead/:lead_id" element={<ViewQuoteLead />} />
        <Route path="/view-deal-list/:lead_id" element={<ViewDealList />} />
        <Route path="/notification" element={<Notification />} />
        <Route path="/print" element={<Print />} />
      </Route>

      {/**
       *  Normal Routes
       */}

      {/* <Route
        path="/salesrepresentative"
        element={<SalesRepresentativeProfile />}
      /> */}
    </Routes>
  );
};

export default Router;
