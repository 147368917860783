import React, { useRef } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import "../../../src/scss/custom.css";
import { Link } from "react-router-dom";
import Config from "../../Config/Constants";
import { BiPrinter } from "react-icons/bi";
import { useReactToPrint } from "react-to-print";
import Test2 from "../PrintSalesViewLead";

const ViewDealList = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <div className="row py-lg-5 my-3 contentArea position-relative justify-content-center">
        <div className="d-flex justify-content-between mb-3">
          <Link to="/deals-list">
            <button className="btn btn-primary text-uppercase btn-md">
              <IoArrowBackOutline fontSize="1.1em" /> Back
            </button>
          </Link>
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-primary text-uppercase btn-md"
              onClick={handlePrint}
            >
              <BiPrinter fontSize="1.5em" /> Print
            </button>
          </div>
        </div>
        <div className="col-xl-12">
          <Test2 ref={componentRef} />
        </div>
      </div>
    </>
  );
};

export default ViewDealList;
