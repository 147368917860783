import React from "react";
import "../../../src/scss/custom.css";
import { FaRegEdit } from "react-icons/fa";
import { FaRegEye } from "react-icons/fa";
//import Sidebar from "../Sidebar";

const CarDealership = () => {
  return (
    <>
      <div className="row py-lg-5 my-3 contentArea position-relative">
        <div className="col-12">
          <div className="card h-100 shadow-sm rounded-5">
            <div className="card-header bg-transparent border-0 p-4 mt-3">
              <h2>Car Dealership</h2>
            </div>
            <table className="table table-md">
              <thead className="container">
                <tr>
                  <th className="text-start">Quote ID</th>
                  <th className="text-start">Car Dealer Name</th>
                  <th className="text-start">Location</th>
                  <th className="text-start">Car Make</th>
                  <th className="text-center">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="ms-3">
                        <td>1</td>
                      </div>
                    </div>
                  </td>
                  <td>Dealer Name 1</td>
                  <td>Australia</td>
                  <td>Brand</td>
                  <td className="text-center">
                    <a className="primary-colour px-3" href="/" alt="Edit">
                      <FaRegEdit fontSize="1.3em" />
                    </a>
                    <a className="primary-colour px-3" href="/" alt="Edit">
                      <FaRegEye fontSize="1.3em" />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="ms-3">
                        <td>2</td>
                      </div>
                    </div>
                  </td>
                  <td>Dealer Name 2</td>
                  <td>Australia</td>
                  <td>Brand</td>
                  <td className="text-center">
                    <a className="primary-colour px-3" href="/" alt="Edit">
                      <FaRegEdit fontSize="1.3em" />
                    </a>
                    <a className="primary-colour px-3" href="/" alt="Edit">
                      <FaRegEye fontSize="1.3em" />
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div className="d-flex align-items-center">
                      <div className="ms-3">
                        <td>3</td>
                      </div>
                    </div>
                  </td>
                  <td>Dealer Name 3</td>
                  <td>Australia</td>
                  <td>Brand</td>
                  <td className="text-center">
                    <a className="primary-colour px-3" href="/" alt="Edit">
                      <FaRegEdit fontSize="1.3em" />
                    </a>
                    <a className="primary-colour px-3" href="/" alt="Edit">
                      <FaRegEye fontSize="1.3em" />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default CarDealership;
