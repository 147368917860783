import React, { useEffect, useState } from "react";
import { FaRegEye } from "react-icons/fa";
import Config from "../../Config/Constants";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { NavLink, useParams } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import LoadingSpinner from "../Spinner/LoadingSpinner";

const Quotationlist = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { lead_id } = useParams();
  const [GetDealListData, setGetDealListData] = useState([]);
  const getLeadData = async () => {
    setIsLoading(true);
    let utoken = localStorage.getItem("Token");
    const response = await fetch(
      Config.BASEURL + `/auth/get_all_sales_quotes`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${utoken}`,
        },
        method: "POST",
        body: JSON.stringify(),
      }
    );
    const data = await response.json();
    if (data.success === true) {
      setIsLoading(false); // Hide loading screen
      setGetDealListData(data.data);
    } else {
      setIsLoading(false); // Hide loading screen
    }
  };
  useEffect(() => {
    getLeadData();
  }, []);

  const handleDetailsDealList = (e, lead_id, dealer_id) => {
    e.preventDefault();
    localStorage.setItem("dealer_id", dealer_id);
    navigate("/view-deal-list/" + lead_id);
  };

  // Accept

  const handleAccept = async (e, status, lead_id, dealer_id) => {
    // alert("Please view deal details \n Are you sure to Accept this.");
    e.preventDefault();
    confirmAlert({
      title: "Confirm to submit",
      childrenElement: () => (
        <div className="whiteText">
          Are the deal details correct? <br /> Would you like to submit this.
        </div>
      ),

      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            setIsLoading(true);
            let utoken = localStorage.getItem("Token");

            const response = await fetch(
              Config.BASEURL + `/auth/lead_accept_reject`,
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${utoken}`,
                },
                method: "POST",
                body: JSON.stringify({
                  quote_id: lead_id,
                  dealer_id: dealer_id,
                  status: 1,
                }),
              }
            );
            const data = await response.json({});
            if (data.success === true) {
              setIsLoading(false); // Hide loading screen
              toast.success(data?.message, {
                position: "top-center",
                autoClose: 1000,
              });

              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  // Reject

  const handleReject = async (e, status, lead_id, dealer_id) => {
    e.preventDefault();
    confirmAlert({
      title: "Confirm to submit",
      childrenElement: () => (
        <div>
          Please view deal details <br /> Are you sure to Reject this.
        </div>
      ),

      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            setIsLoading(true);
            let utoken = localStorage.getItem("Token");

            const response = await fetch(
              Config.BASEURL + `/auth/lead_accept_reject`,
              {
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${utoken}`,
                },
                method: "POST",
                body: JSON.stringify({
                  quote_id: lead_id,
                  dealer_id: dealer_id,
                  status: 0,
                }),
              }
            );
            const data = await response.json({});
            if (data.success === true) {
              setIsLoading(false); // Hide loading screen
              toast.success(data?.message, {
                position: "top-center",
                autoClose: 1000,
              });

              setTimeout(() => {
                window.location.reload();
              }, 2000);
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleFilter = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    let utoken = localStorage.getItem("Token");

    const response = await fetch(
      Config.BASEURL + `/auth/get_all_sales_quotes`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${utoken}`,
          Accept: "application/json",
        },
        body: JSON.stringify({ status: e.target.value }),
      }
    );
    let parsedResponse = await response.json();
    if (parsedResponse?.success === true) {
      setIsLoading(false);
      setGetDealListData(parsedResponse.data);
    } else if (parsedResponse?.success === false) {
      setGetDealListData(parsedResponse.data);
      toast.error(parsedResponse?.message, {
        position: "top-center",
        autoClose: 1000,
      });
    }
  };

  return (
    <>
      <div className="row py-lg-5 my-3 contentArea position-relative">
        <div className="col-12">
          <div className="card h-100 shadow-sm rounded-5">
            {isLoading ? <LoadingSpinner /> : getLeadData}
            <div className="card-header bg-transparent border-0 p-4 mt-3">
              <h2>Quote List</h2>
            </div>
            <div className="d-flex flex-wap align-items-center justify-content-end p-3">
              <label className="mx-2">Status Filter</label>

              <select
                className="form-control form-select form-select-sm"
                onChange={(e) => {
                  handleFilter(e);
                }}
              >
                <option value="All">All</option>
                <option value="Quoted">Quoted</option>
                <option value="Pending">Yet to Quote</option>
                <option value="Missed">Deal Closed</option>
                <option value="Accepted">Accepted</option>
                <option value="Rejected">Rejected</option>
              </select>
            </div>
            <div className="table-responsive">
              <table className="table table-md">
                <thead className="container">
                  <tr>
                    <th className="text-center">Deals ID</th>
                    <th className="text-start">Car Dealer Name</th>
                    <th className="text-start">Car Make</th>
                    <th className="text-start">Car Model</th>
                    <th className="text-center">Amount</th>
                    <th className="text-start">Status</th>
                    <th className="text-center">View Deals Details</th>
                    <th className="text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {GetDealListData.length > 0 ? (
                    GetDealListData.map((curElem, i) => (
                      <tr>
                        <td className="text-center">{curElem.lead_id}</td>
                        <td>{curElem.dealer_name}</td>
                        <td>{curElem.make.toUpperCase()}</td>
                        <td>{curElem.model.toUpperCase()}</td>
                        <td className="text-center">{curElem.amount}</td>

                        <td>{curElem.status_name}</td>
                        <td className="text-center">
                          <NavLink
                            onClick={(e) =>
                              handleDetailsDealList(
                                e,
                                curElem?.lead_id,
                                curElem?.dealer_id
                              )
                            }
                            to={{
                              pathname: "/view-deal-list",
                            }}
                          >
                            <FaRegEye fontSize="1.3em" />
                          </NavLink>
                        </td>
                        <td className="text-center">
                          {curElem?.status == "2" ? (
                            <>
                              <button
                                className="btn btn-primary text-uppercase btn-sm m-1"
                                onClick={(e) =>
                                  handleAccept(
                                    e,
                                    curElem?.status,
                                    curElem?.lead_id,
                                    curElem?.dealer_id
                                  )
                                }
                              >
                                Accept
                              </button>
                              <button
                                className="btn btn-primary text-uppercase btn-sm m-1"
                                onClick={(e) =>
                                  handleReject(
                                    e,
                                    curElem?.status,
                                    curElem?.lead_id,
                                    curElem?.dealer_id
                                  )
                                }
                              >
                                Reject
                              </button>
                            </>
                          ) : (
                            "-"
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" align="center">
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Quotationlist;
