import React, { useState, useEffect } from "react";
import Config from "../Config/Constants";
import LoadingSpinner from "./Spinner/LoadingSpinner";
import { NavLink, useParams } from "react-router-dom";
import { useNavigate } from "react-router";

const Notification = () => {
  const [GetLeadData, setGetLeadData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { lead_id } = useParams();
  const Role_id = localStorage.getItem("Role_id");

  const getStaffData = async () => {
    setIsLoading(true);
    let utoken = localStorage.getItem("Token");
    const response = await fetch(Config.BASEURL + `/auth/user_notifications`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${utoken}`,
      },
      method: "POST",
      body: JSON.stringify(),
    });
    const data = await response.json();
    if (data.success === true) {
      setIsLoading(false); // Hide loading screen
      setGetLeadData(data.data);
    }
  };
  useEffect(() => {
    getStaffData();
  }, []);

  const handleDetailsDealList = (e, lead_id, dealer_id) => {
    e.preventDefault();
    localStorage.setItem("dealer_id", dealer_id);
    navigate("/view-deal-list/" + lead_id);
  };

  return (
    <>
      <div className="row pt-lg-5 pb-lg-5 mt-3 contentArea position-relative">
        <div className="col-12">
          <div className="card h-100 shadow-sm rounded-5">
            {isLoading ? <LoadingSpinner /> : getStaffData}
            <div className="card-header bg-transparent border-0 p-4 mt-3">
              <h2>Notifications</h2>
            </div>
            <div className="table-responsive">
              <table className="table table-md">
                <thead className="container">
                  <tr>
                    <th className="text-center">Leads ID</th>
                    {Role_id == 2 ? (
                      <th className="text-center">Dealer Name</th>
                    ) : (
                      <th className="text-center">Sales Representative Name</th>
                    )}
                    <th className="text-start">Messages</th>
                    <th className="text-start">Date & Time</th>
                  </tr>
                </thead>
                <tbody>
                  {GetLeadData.length > 0 ? (
                    GetLeadData.map((curElem, i) => {
                      return (
                        <tr>
                          <td className="text-center">
                            <NavLink
                              onClick={(e) =>
                                handleDetailsDealList(
                                  e,
                                  curElem?.lead_id,
                                  curElem?.dealer_id
                                )
                              }
                              to={{
                                pathname: "/view-deal-list",
                              }}
                            >
                              {curElem.lead_id}
                            </NavLink>
                          </td>
                          {Role_id == 2 ? (
                            <td className="text-center">
                              {curElem.dealer_name}
                            </td>
                          ) : (
                            <td className="text-center">
                              {curElem.sales_represntative}
                            </td>
                          )}
                          <td
                            dangerouslySetInnerHTML={{
                              __html: curElem.message,
                            }}
                          ></td>
                          <td>{curElem.created_at}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="3" align="center">
                        No Record Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
