import React from "react";

const Submitquote = () => {
  return (
    <>
      <div className="row py-lg-5 my-3 contentArea position-relative justify-content-center">
        <div className="col-md-8">
          <div className="card h-100 shadow-sm rounded-5 p-4 ">
            <div className="alert secondary-colour border-0 m-0 ">
              <div className="whiteText">
                <h2 className="mb-3 primary-colour">Your quote has been sent successfully.</h2>
                <h5 className="mb-4">
                  Please wait you will be notified once you will <br />receive the quote from Car Dealers.
                </h5>
                <a href="/deals-list" className="btn btn-primary text-uppercase btn-md">OK</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Submitquote;